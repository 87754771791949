import React, { useState, useEffect } from "react";
import { LeftArrowIcon, DeleteIcon } from "./AppIcons";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { findFileTypeFromURL } from "../../utils/findFileTypeFromURL";
import { useSwipeable } from "react-swipeable";

export default function ImgPopup({
  view,
  setView,
  files,
  file_index,
  onChange,
  delete_file,
  container_className,
  image_className,
  type,
}) {
  const [render, set_render] = useState(true);
  const [img_onError, set_img_onError] = useState(false);

  useEffect(() => {
    set_render(false);

    setTimeout(() => {
      set_render(true);
    }, 0);
  }, [file_index]);

  useEffect(() => {
    set_img_onError(false);
  }, [view]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (file_index !== files.length - 1) {
        onChange(file_index + 1);
        set_img_onError(false);
      }
      set_img_onError(false);
    },
    onSwipedRight: () => {
      if (file_index !== 0) {
        onChange(file_index - 1);
        set_img_onError(false);
      }
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  if (!view) return null;
  else if (!files[file_index] || !render)
    return (
      <div
        className={
          "bg-black bg-opacity-50 z-30 border shadow rounded-md inset-0 fixed overflow-hidden flex justify-end " +
          container_className
        }
      />
    );
  else
    return (
      <div
        className={
          "bg-black bg-opacity-50 z-30 border shadow rounded-md inset-0 fixed overflow-auto sm:overflow-hidden flex flex-col gap-2 justify-center h-screen " +
          container_className
        }
        {...handlers}
      >
        <div className="w-full flex flex-row-reverse absolute top-0">
          <div className="flex flex-col gap-4 items-center">
            <div
              onClick={() => {
                setView();
              }}
              className={"relative top-3 right-10 flex justify-end delay-200"}
            >
              <span className="h-10 w-10 flex items-center justify-center rounded bg-white hover:bg-gray-100 cursor-pointer shadow text-xl text-red-500 hover:text-red-700">
                &#10005;
              </span>
            </div>
            {delete_file && (
              <div
                onClick={() => {
                  delete_file();
                }}
                className="relative top-3 right-10 h-10 w-10 flex items-center justify-center rounded bg-red-600 hover:bg-red-500 shadow text-xl text-white cursor-pointer"
              >
                <DeleteIcon className="h-5 w-5" />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex flex-row gap-5 flex-grow items-center w-full justify-center">
            <div
              onClick={() => {
                if (file_index !== 0) {
                  onChange(file_index - 1);
                  set_img_onError(false);
                }
              }}
              className={
                "hidden sm:flex items-center justify-center relative left-3 p-3 sm:p-4 rounded-full bg-white " +
                (file_index !== 0
                  ? "text-white delay-100 duration-200 hover:bg-gray-800 hover:bg-opacity-30 bg-opacity-30"
                  : "opacity-0")
              }
            >
              <LeftArrowIcon className="h-4 sm:h-6" />
            </div>
            <div className="w-full h-full flex flex-col gap-1 items-center justify-center">
              {(
                type
                  ? type === "image"
                  : findFileTypeFromURL(files[file_index]) === "image"
              ) ? (
                <img
                  src={files[file_index]}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(files[file_index]);
                  }}
                  className={
                    "object-scale-down max-h-screen py-5 cursor-pointer min-w-full " +
                    image_className
                  }
                />
              ) : (
                  type
                    ? type === "video"
                    : findFileTypeFromURL(files[file_index]) === "video"
                ) ? (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={
                    "max-h-screen py-5 transiton duration-200 min-w-full " +
                    image_className
                  }
                >
                  <ReactPlayer
                    style={{ minWidth: "100%" }}
                    controls
                    url={files[file_index]}
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : (
                  type
                    ? type === "audio"
                    : findFileTypeFromURL(files[file_index]) === "audio"
                ) ? (
                <ReactAudioPlayer
                  onClick={(e) => e.stopPropagation()}
                  src={files[file_index]}
                  controls
                />
              ) : (
                <img
                  src={
                    files[file_index].split(".").slice(0, -1).join(".") + ".jpg"
                  }
                  onError={() => set_img_onError(true)}
                  onClick={() => window.open(files[file_index])}
                  alt="Open Link"
                  className={
                    "object-scale-down max-h-screen cursor-pointer min-w-full " +
                    (img_onError
                      ? "bg-white px-3 py-1 rounded text-blue-600 hover:underline "
                      : "py-5") +
                    image_className
                  }
                />
              )}
            </div>
            <div
              onClick={() => {
                if (file_index !== files.length - 1) {
                  onChange(file_index + 1);
                  set_img_onError(false);
                }
              }}
              className={
                "hidden sm:flex items-center justify-center relative right-3 p-3 sm:p-4 rounded-full bg-white " +
                (file_index !== files.length - 1
                  ? "text-white delay-100 duration-200 hover:bg-gray-800 hover:bg-opacity-30 bg-opacity-30"
                  : "opacity-0")
              }
            >
              <LeftArrowIcon className="transform rotate-180 h-4 sm:h-6" />
            </div>
          </div>
          <div className="sm:hidden flex flex-row items-center gap-3 mx-auto">
            {files.map((val, index) => (
              <button
                className={
                  "h-4 w-4 rounded-full cursor-pointer " +
                  (file_index === index
                    ? "bg-white"
                    : "bg-gray-500 bg-opacity-50")
                }
                onClick={() => {
                  onChange(index);
                  set_img_onError(false);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
}
