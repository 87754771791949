import React from "react";
import { CrossIcon } from "../../common/AppIcons";
import StatusBox from "../common/StatusBox";

export default function MaterialPopup({ material, close_popup }) {
  const details = [
    {
      label: "Method",
      content: material.method ? (
        <StatusBox status={material.method} className="text-sm" />
      ) : (
        "----"
      ),
    },
    {
      label: "Status",
      content:
        material.method !== "IN_STOCK" ? (
          <StatusBox
            className="text-sm"
            status={material.isDelivered ? "DELIVERED" : "UNDELIVERED"}
          />
        ) : (
          "----"
        ),
    },
    {
      label: "Description",
      content: material.description ?? "----",
    },
    {
      label: "SKU number",
      content: material.skuNumber ?? "----",
    },
    {
      label: "Supplier",
      content: material.supplier?.name ?? "----",
    },
    {
      label: "Supplier branch",
      content: material.supplierBranch?.address
        ? (material.supplierBranch?.address?.streetAddress1 ?? "") +
          "  " +
          (material.supplierBranch?.address?.streetAddress2
            ? material.supplierBranch?.address?.streetAddress2 + ",  "
            : "") +
          (material.supplierBranch?.address?.city ?? "") +
          "  " +
          (material.supplierBranch?.address?.zip ?? "")
        : "----",
    },
  ];
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col items-center bg-white max-w-xl w-full p-4 px-6 pt-3 sm:p-9 sm:px-12 sm:pt-6 rounded shadow-md"
    >
      <div className="w-full flex flex-row-reverse flex-wrap justify-between">
        <div className="cursor-pointer" onClick={close_popup}>
          <CrossIcon
            style={{ height: "29px" }}
            className="text-red-600 hover:text-red-400"
          />
        </div>
        <span className="text-lg sm:text-2xl font-semibold text-gray-900">
          {material?.name ?? ""}
        </span>
      </div>

      {details?.map((detail, detail_index) => (
        <div
          key={detail_index}
          style={{ fontSize: "14px" }}
          className="mt-5 w-full grid grid-cols-3 sm:grid-cols-4 gap-3"
        >
          <span className="text-gray-600">{detail.label}</span>
          <div className="text-gray-800 col-span-2 sm:col-span-3">
            {detail.content}
          </div>
        </div>
      ))}

      {material.supplierBranch?.address?.geocodeLatitude &&
        material.supplierBranch?.address?.geocodeLongitude && (
          <div className="mt-5 w-full grid grid-cols-3 sm:grid-cols-4 gap-3">
            <div />
            <div className="col-span-2 sm:col-span-3 flex items-center">
              <a
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  material.supplierBranch?.address?.geocodeLatitude +
                  "," +
                  material.supplierBranch?.address?.geocodeLongitude
                }
                target="_blank"
                className="px-5 py-2 rounded flex flex-row items-center gap-3 text-sm text-gray-500 hover:text-gray-600 duration-200 hover:bg-gray-200 border border-gray-400"
              >
                <img src="/google_map_link.png" className="h-5 w-5" />
                Open Address in Maps
              </a>
            </div>
          </div>
        )}
    </div>
  );
}
