import React, { useState, useEffect } from "react";

export default function SelectHeadings({
    headings,
    selected,
    onChange,
    className,
    base_heading_width = 150,
    width_array,
    gap,
}) {
    const [heading_width, set_heading_width] = useState(
        width_array ?? headings.map(() => base_heading_width)
    );

    useEffect(() => {
        window.addEventListener("resize", () => {
            set_heading_width(() => {
                if (window.innerWidth < 768)
                    return (width_array ?? headings.map(() => base_heading_width)).map(
                        (val) => val * 0.7
                    );
                else return width_array ?? headings.map(() => base_heading_width);
            });
        });
    }, []);

    const find_selected_heading_index = () => {
        let ans = 0;
        headings.map((val, index) => {
            if (val.value === selected) ans = index;
        });
        return ans;
    };

    return (
        <div
            className={
                "flex flex-col gap-px overflow-auto flex-grow mt-3 md:mt-0 " + className
            }
        >
            <div
                className="flex flex-row items-center w-full mb-1"
                style={{ gap: (gap ?? "10") + "px" }}
            >
                {headings.map((ele, ele_index) => (
                    <div
                        key={ele_index}
                        onClick={() => onChange(ele.value)}
                        style={{
                            minWidth: heading_width[ele_index] + "px",
                            maxWidth: heading_width[ele_index] + "px",
                        }}
                        className={"flex flex-col items-center gap-px cursor-pointer"}
                    >
                        <span
                            className={
                                "font-semibold text-sm md:text-base md:whitespace-pre truncate break-words " +
                                (ele.value === selected ? "text-indigo-600" : "text-gray-600")
                            }
                            style={{ lineHeight: "1.5em" }}
                        >
                            {ele.label}
                        </span>
                    </div>
                ))}
            </div>
            <div
                className="bg-indigo-500 rounded transform-all duration-300 delay-100 "
                style={{
                    height: "6px",
                    width: heading_width[find_selected_heading_index()],
                    marginLeft:
                        heading_width.reduce((sum, val, index) => {
                            return index > find_selected_heading_index() - 1
                                ? sum
                                : sum + val + (gap ?? 10);
                        }, 0) + "px",
                }}
            />
        </div>
    );
}
