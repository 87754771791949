import React from "react";
import { formatPhoneNumber } from "react-phone-number-input";

import { PersonIcon2, PhoneIcon } from "../../common/AppIcons";

export default function Contact({ index, name, phone }) {
  return (
    <div className="flex flex-row items-start">
      <div className="flex flex-row">
        <PersonIcon2
          className="mt-1"
          style={{ height: "18px", color: "#605BFF" }}
        />
        {index && (
          <span
            style={{ fontSize: "8px", backgroundColor: "#605BFF" }}
            className="relative right-3 top-3 w-3 h-3 flex items-center justify-center rounded-full text-white"
          >
            <span>{index}</span>
          </span>
        )}
      </div>
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4 gap-y-2">
        <span className="text-gray-800 font-semibold">{name}</span>
        {phone && (
          <a
            href={"tel:" + phone}
            // style={{ backgroundColor: "#FAFAFB" }}
            className="bg-white flex flex-row items-center py-1 pr-3 transform hover:scale-105 duration-200 rounded border border-gray-300 text-gray-600"
          >
            <PhoneIcon style={{ height: "16px" }} />

            {formatPhoneNumber("" + phone) === ""
              ? phone
              : formatPhoneNumber("" + phone)}
          </a>
        )}
      </div>
    </div>
  );
}
