import { EventEmitter } from "events";
import dispatcher from "../dispatcher";
import { ACCESS_TOKEN, ADMIN_TOKEN, getCurrentUser } from "../api/Api";

class ActiveUserStore extends EventEmitter {
  constructor() {
    super();
    this.activeUser = {
      loading: true,
    };
    getCurrentUser()
      .then((response) => {
        this.activeUser = { ...response, loading: false };
        this.emit("change");
      })
      .catch((error) => {
        this.activeUser = {
          loading: false,
        };
        this.emit("change");
      });
  }
  setNewUser(userCredentials) {
    this.activeUser = {
      ...userCredentials,
      loading: false,
    };
    this.emit("change");
  }
  loadCurrentUser() {
    getCurrentUser()
      .then((response) => {
        console.log("user data", this.activeUser, response);
        this.activeUser = { ...response, loading: false };
        this.emit("change");
      })
      .catch((error) => {
        this.activeUser = {
          loading: false,
        };
        console.log("error", error);
        this.emit("change");
      });
  }
  logoutUser() {
    localStorage.removeItem(ACCESS_TOKEN);
    if (localStorage.getItem(ADMIN_TOKEN)) {
      localStorage.setItem(ACCESS_TOKEN, localStorage.getItem(ADMIN_TOKEN));
      localStorage.removeItem(ADMIN_TOKEN);
    } else this.activeUser = { loading: false };
    this.emit("change");
  }
  getActiveUser() {
    return this.activeUser;
  }
  isLoggedIn() {
    return this.activeUser.roles && this.activeUser.roles.includes("ROLE_USER");
  }
  isAdmin() {
    if (this.activeUser.roles && this.activeUser.roles.includes("ROLE_ADMIN"))
      return true;
    return false;
  }
  isSuper() {
    if (
      this.activeUser.roles &&
      this.activeUser.roles.includes("ROLE_SUPER_ADMIN")
    )
      return true;
    return false;
  }
  switchUser(token) {
    localStorage.setItem(ADMIN_TOKEN, localStorage.getItem(ACCESS_TOKEN));
    localStorage.setItem(ACCESS_TOKEN, token.accessToken);
    window.location.reload();
  }
  handleActions(action) {
    switch (action.type) {
      case "updateActiveUser": {
        this.loadCurrentUser();
        break;
      }
      case "logoutUser": {
        this.logoutUser();
        break;
      }
      default: {
        return;
      }
    }
  }
}

const activeUserStore = new ActiveUserStore();
dispatcher.register(activeUserStore.handleActions.bind(activeUserStore));
window.dispatcher = dispatcher;

export default activeUserStore;
