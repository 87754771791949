/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AppLayout from "../common/AppLayout";
import MapComponent from "./MapComponent";
import UserProfile from "../user/UserProfile";
import { getAppointments, getUserInfo } from "../../api/Api";
import ActiveUserStore from "../../store/ActiveUserStore";
import Popup from "../common/Popup";
import { dateWithOrdinalSuffix } from "../../utils/DateUtils";
import SRDetail from "../serviceRequest/SRDetail";
import TopButtons from "./TopButtons";
import {
  CalenderIcon2,
  ClockIcon2,
  LoadingIcon,
  LocationIcon,
  Logo,
} from "../common/AppIcons";
import ChatComponent from "../chat/ChatComponent";
import TicketIdWithPrefix from "../common/TicketIdWithPrefix";
import StatusBox from "../serviceRequest/common/StatusBox";
import { findAppointmentStatus } from "./utility/findAppointmentStatus";
import { convertTimezone } from "../../utils/convertTimezone";
import { addressFormat } from "../../utils/AddressFormat";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const themes = [
  { color: "bg-blue-500", hover: "hover:bg-blue-500", text: "text-blue-500" },
  { color: "bg-red-500", hover: "hover:bg-red-500", text: "text-red-500" },
  {
    color: "bg-yellow-500",
    hover: "hover:bg-yellow-500",
    text: "text-yellow-500",
  },
  {
    color: "bg-green-500",
    hover: "hover:bg-green-500",
    text: "text-green-500",
  },
  {
    color: "bg-purple-500",
    hover: "hover:bg-purple-500",
    text: "text-purple-500",
  },
];

const apiFormat = (date) => {
  var a = new Date(date.getFullYear(), date.getMonth());
  var ans = {
    timelineStart:
      a.getFullYear() +
      "-" +
      ("0" + (a.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + a.getDate()).slice(-2),
  };
  a.setMonth(a.getMonth() + 1);
  a.setDate(a.getDate() - 1);
  return {
    ...ans,
    timelineEnd:
      a.getFullYear() +
      "-" +
      ("0" + (a.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + a.getDate()).slice(-2),
  };
};

export default function DashBoard({ appointmentId, parameter, screen }) {
  const [appointments, setAppointments] = useState([]);
  const [appointments_loading, set_appointments_loading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [date, setDate] = useState(
    new Date(
      new Date().setTime(
        new Date().getTime() -
          1000 * 60 * (new Date().getTimezoneOffset() + 360)
      )
    )
  );
  const [dateFilter, setDateFilter] = useState();
  const [loading, set_loading] = useState(false);
  const [dayNoteElongate, setDayNoteElongate] = useState(-1);
  const [associateData, setAssociateData] = useState({});
  const [main_screen, set_main_screen] = useState({ screen: "MAP" });
  // MAP, MESSAGE, SR
  const [screen_size, set_screen_size] = useState(
    "sm"
    // 'lg'
  );

  useEffect(() => {
    if (screen === "messages") {
      window.innerWidth > 1024
        ? set_main_screen({ screen: "MESSAGE" })
        : (window.location.href = "/messages");
    }
  }, []);

  useEffect(() => {
    if (appointmentId) {
      set_main_screen({
        screen: "SR",
        appointmentId: appointmentId,
        parameter: parameter,
      });
    }
  }, []);

  useEffect(() => {
    const setScreenSize = () => {
      set_screen_size(window.innerWidth > 1024 ? "lg" : "sm");
    };

    window.addEventListener("resize", setScreenSize);
    setScreenSize();
    return () => window.removeEventListener("resize", setScreenSize);
  }, []);

  useEffect(() => {
    getUserInfo()
      .then((data) => {
        setAssociateData(data);
        console.log("user Data Received from Server", data);
      })
      .catch((data) => {
        console.log("error", data);
      });
  }, []);

  useEffect(() => {
    get_appointments();
  }, []);

  const get_appointments = () => {
    set_appointments_loading(true);
    getAppointments(apiFormat(date))
      .then((data) => {
        set_appointments_loading(false);
        console.log("Data Received from Server", data);
        setAppointments(data);
      })
      .catch((data) => {
        set_appointments_loading(false);
        console.log("api request failed with data:", data);
        setAppointments([]);
      });
  };

  const monthIncrease = () => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
    setDateFilter();
    setAppointments([]);
    setTimeout(() => {
      get_appointments();
    }, 100);
  };

  const monthDecrease = () => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
    setDateFilter();
    setAppointments([]);
    setTimeout(() => {
      get_appointments();
    }, 100);
  };

  return (
    <AppLayout
      togglePopup={() => {
        setPopup(!popup);
      }}
    >
      {loading && (
        <div className="fixed flex items-center justify-center z-30 inset-0 bg-opacity-80 bg-white">
          <div
            style={{ borderTopColor: "transparent" }}
            className="border-solid animate-spin rounded-full border-blue-400 border-4 h-12 w-12"
          ></div>
        </div>
      )}

      <div
        style={{ backgroundColor: "#FAFAFB" }}
        className="flex flex-col lg:flex-row-reverse w-full h-screen overflow-y-auto"
      >
        <div id="main_section" className={"w-full lg:w-8/12 lg:h-screen"}>
          <TopButtons
            userData={associateData}
            openUserProfile={() => setPopup(true)}
            main_screen={main_screen.screen}
            set_main_screen={(val) => set_main_screen({ screen: val })}
            logOut={(_) => {
              console.log("Logging Out");
              ActiveUserStore.logoutUser();
            }}
            screen_size={screen_size}
          />

          {main_screen.screen === "MESSAGE" && screen_size !== "sm" ? (
            <ChatComponent
              go_back={() => set_main_screen({ screen: "MAP" })}
              component_className="relative max-w-full"
            />
          ) : main_screen.screen === "SR" ? (
            <SRDetail
              appointmentId={main_screen.appointmentId}
              parameter={main_screen.parameter}
              loading={loading}
              set_loading={set_loading}
              go_back={() => set_main_screen({ screen: "MAP" })}
              get_appointments={get_appointments}
            />
          ) : (
            <MapComponent
              themes={themes}
              getAppointmentDetails={(id) => {
                set_main_screen({ screen: "SR", appointmentId: id });
              }}
              dayNoteElongate={dayNoteElongate}
              data={appointments.filter((appointment) => {
                if (dateFilter) {
                  const appointmentDate = new Date(appointment.timelineStart);
                  return (
                    appointmentDate.getDate() === dateFilter.getDate() &&
                    appointmentDate.getMonth() === dateFilter.getMonth() &&
                    appointmentDate.getFullYear() === dateFilter.getFullYear()
                  );
                }
                return true;
              })}
            />
          )}
        </div>
        {/* left most section */}
        <div className="overflow-y-auto m-auto w-full lg:h-screen gap-3 lg:w-4/12">
          <div
            onClick={() => {}}
            className="border-r-2 border-gray-300 flex flex-col w-full bg-gray-50"
          >
            <Logo className="w-20 ml-3 lg:ml-5 mt-16 sm:mt-5" />

            <div className="mt-2 w-full flex flex-row justify-start items-center p-3 lg:pl-5">
              <span
                className="text-xl font-semibold"
                style={{ fontFamily: "Nunito" }}
              >
                Service Requests
              </span>
            </div>
            {
              <div className={"my-1 relative"}>
                <Calendar
                  monthIncrease={monthIncrease}
                  monthDecrease={monthDecrease}
                  appointments={appointments}
                  date_selected={dateFilter}
                  onSelectCB={(date) => setDateFilter(date)}
                />
              </div>
            }
            {/* Clear Date Filter Button */}
            {dateFilter && (
              <div className="p-3 lg:pl-7 my-2 px-1 md:px-4 flex justify-end w-full">
                <button
                  className="transition duration-300 text-gray-700 cursor-pointer rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-center float-right p-2 px-4"
                  onClick={() => {
                    setDateFilter();
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-sm" />
                  <span className="px-2 text-sm">Clear selection</span>
                </button>
              </div>
            )}

            <div
              style={{ minHeight: "300px" }}
              className="p-3 lg:px-9 mt-4 lg:mt-0 overflow-y-auto w-full h-full"
            >
              {appointments_loading ? (
                <LoadingIcon className="text-blue-500 h-6 w-6 mx-auto my-5" />
              ) : appointments.filter((appointment) => {
                  if (dateFilter) {
                    const appointmentDate = new Date(appointment.timelineStart);
                    return (
                      appointmentDate.getDate() === dateFilter.getDate() &&
                      appointmentDate.getMonth() === dateFilter.getMonth() &&
                      appointmentDate.getFullYear() === dateFilter.getFullYear()
                    );
                  }
                  return true;
                }).length === 0 ? (
                <div className="w-full px-6 py-4 text-center text-gray-500">
                  No appointments found
                </div>
              ) : null}
              {appointments
                .filter((appointment) => {
                  if (dateFilter) {
                    const appointmentDate = convertTimezone(
                      appointment.timelineStart,
                      appointment.timezone
                    );
                    return (
                      appointmentDate.getDate() === dateFilter.getDate() &&
                      appointmentDate.getMonth() === dateFilter.getMonth() &&
                      appointmentDate.getFullYear() === dateFilter.getFullYear()
                    );
                  }
                  return true;
                })
                .map((value, index) => {
                  return (
                    <div
                      className={
                        "rounded-lg flex flex-row bg-white gap-4 p-2 mb-1 cursor-pointer transform duration-700"
                      }
                      onMouseEnter={() => {
                        setDayNoteElongate(index);
                      }}
                      onMouseLeave={() => {
                        setDayNoteElongate(-1);
                      }}
                      onClick={() => {
                        set_main_screen({
                          screen: "SR",
                          appointmentId: value.appointmentId,
                        });
                      }}
                    >
                      <div
                        className={
                          "w-2 min-h-full my-1 rounded-full translate-y-px transform duration-700 " +
                          themes[index % 5].color
                        }
                      />

                      <div
                        className={
                          "py-px flex flex-col transform duration-500 justify-start w-full h-full"
                        }
                      >
                        <div className="w-full flex flex-row gap-3 items-center">
                          <a className="font-semibold">{value.clientName}</a>
                          <div className="flex flex-grow flex-row-reverse flex-wrap gap-1">
                            <StatusBox
                              className="text-xs"
                              status={value.appointmentType}
                            />
                            <StatusBox
                              className="text-xs"
                              status={findAppointmentStatus({
                                type: value.appointmentType,
                                status: value.status,
                              })}
                            />
                            {value.remedial && (
                              <StatusBox
                                className="text-xs"
                                status={"REMEDIAL"}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-2 mt-3 text-gray-800">
                          <span className="flex flex-row items-center">
                            <CalenderIcon2
                              style={{ height: "18px" }}
                              className="relative right-2"
                            />
                            {
                              days[
                                convertTimezone(
                                  value.timelineStart,
                                  value.timezone
                                ).getDay()
                              ]
                            }
                            ,{" "}
                            {dateWithOrdinalSuffix(
                              convertTimezone(
                                value.timelineStart,
                                value.timezone
                              )
                            )}{" "}
                            {convertTimezone(
                              value.timelineStart,
                              value.timezone
                            ).toLocaleString([], {
                              month: "long",
                            })}
                          </span>
                          <div className="flex flex-row w-full justify-between items-center">
                            <span className="flex flex-row items-center gap-4">
                              <ClockIcon2 className="h-4" />
                              {convertTimezone(
                                value.timelineStart,
                                value.timezone
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}{" "}
                              -{" "}
                              {convertTimezone(
                                value.timelineEnd,
                                value.timezone
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                            <span className="text-sm opacity-60">
                              {TicketIdWithPrefix(value)}
                            </span>
                          </div>

                          <span
                            className={
                              "pb-1 flex flex-row items-start gap-2 transform -translate-x-1 overflow-hidden duration-700 " +
                              (dayNoteElongate === index
                                ? "max-h-96"
                                : "max-h-96 sm:max-h-0")
                            }
                          >
                            <span>
                              <LocationIcon
                                style={{ width: "25px", height: "18px" }}
                              />
                            </span>
                            {addressFormat(value)}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Popup
        view={popup}
        onClose={() => {
          setPopup(!popup);
        }}
      >
        <UserProfile
          onClose={() => {
            setPopup(!popup);
          }}
        />
      </Popup>
    </AppLayout>
  );
}
