import React from 'react';

export default function Popup({ view, onClose, children, className = "" }) {

    return (<div className={"h-screen w-screen inset-0 absolute z-30 transform duration-200 " + (view ? "opacity-100 scale-100" : "opacity-0 scale-0 delay-200")}>
        <div className={"w-full h-full relative sm:p-10 flex items-center justify-center bg-black duration-200 " + (view ? "bg-opacity-50 delay-300" : "bg-opacity-0")}>

            <div className={"max-h-full h-full lg:max-w-4xl shadow bg-white z-20 rounded overflow-y-auto " + className}>

                {children}
            </div>
        </div>
    </div>);
}