import { useEffect } from "react";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

import { API_BASE_URL } from "../api/Api";

export default function WebSocket({ url, onMessage, reset_array = [] }) {
  useEffect(() => {
    if (!url) return;
    var sock = new SockJS(API_BASE_URL.slice(0, -4) + "/ws-sockjs");

    var client = Stomp.over(sock);

    client.connect("", "", (e) => {
      console.log("web socket connected", e);
      client.subscribe(url, onMessage);
    });
    client.heartbeat.outgoing = 20000;
    client.heartbeat.incoming = 0;
    // client.debug = (e) => {
    //   console.log("debug", e);
    // };

    return () => {
      try {
        client.disconnect((e) => console.log("disconnected", e));
      } catch {
        console.log("error when tried to disconnect from chat websocket");
      }
    };
  }, [...reset_array, API_BASE_URL, url]);

  return <div></div>;
}
