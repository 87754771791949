export const API_BASE_URL = process.env.REACT_APP_API_URL; //"https://helpsquadapi.gigin.dev/api" | process.env.REACT_APP_API_BASE_URL
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const ADMIN_TOKEN = "ADMIN_TOKEN";

const urlParams = (params) => {
  console.log("Parameterizing", Object.entries(params));
  return Object.entries(params).reduce(
    (url, [key, value]) =>
      value
        ? url === ""
          ? `?${key}=${value}`
          : `${url}&${key}=${value}`
        : url,
    ""
  );
};

const request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/auth/me",
    method: "GET",
  });
}

export function login(data) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function resetPassword(email) {
  return request({
    url: API_BASE_URL + "/user/forgotPassword",
    method: "POST",
    body: JSON.stringify({ value: email }),
  });
}

export function recoverPassword(resetPassword) {
  return request({
    url: API_BASE_URL + "/user/resetPasswordRecovery",
    method: "POST",
    body: JSON.stringify(resetPassword),
  });
}

export function verifyRecovery(resetPassword) {
  return request({
    url: API_BASE_URL + "/user/verifyPasswordRecovery",
    method: "POST",
    body: JSON.stringify(resetPassword),
  });
}
export function getAppointments(dates) {
  return request({
    url: API_BASE_URL + "/sr/getAppointments",
    method: "POST",
    body: JSON.stringify(dates),
  });
}
export function getUserInfo() {
  return request({
    url: API_BASE_URL + "/associate/getAssociateInfo",
    method: "GET",
  });
}
export function updateUserInfo(userInfo) {
  return request({
    url: API_BASE_URL + "/associate/update",
    method: "POST",
    body: JSON.stringify(userInfo),
  });
}

export function getAddressAutoComplete({ address, state = "CA" }) {
  return request({
    url:
      API_BASE_URL +
      "/address/getMelissaAddresses" +
      urlParams({ address, state }),
    method: "GET",
  });
}

export function fileUpload(data) {
  return request({
    url: API_BASE_URL + "/associate/uploadWeb",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getSRDetails(appointmentId) {
  return request({
    url: API_BASE_URL + "/sr/getByAppointmentIdNew/" + appointmentId,
    method: "GET",
  });
}

export function updateAppointmentStatus(data) {
  return request({
    url: API_BASE_URL + "/sr/updateStatus",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getDashboardMessages({ data }) {
  return request({
    url: API_BASE_URL + "/communication/getMessagesForAssociate",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function postDashboardMessages({ data }) {
  return request({
    url: API_BASE_URL + "/communication/sendInternalMessageNew",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getNotifications({ page = 0, size = 5 }) {
  return request({
    url: API_BASE_URL + "/notification/associate",
    method: "POST",
    body: JSON.stringify({ page, size }),
  });
}

export function notificationMarkAsRead({ notificationId }) {
  return request({
    url: API_BASE_URL + "/notification/markAsReadForAssociate",
    method: "POST",
    body: JSON.stringify(notificationId),
  });
}

export function postFirebaseToken({ firebaseToken }) {
  return request({
    url: API_BASE_URL + "/auth/registerDevice",
    method: "POST",
    body: JSON.stringify({ token: firebaseToken }),
  });
}
