import React from "react";
import {
  HouseIcon,
  LeftArrowIcon,
  LocationIcon,
  TruckIcon,
} from "../../common/AppIcons";
import { addressFormat } from "../../../utils/AddressFormat";

export default function Pickup({ supply_points, serviceRequest, className }) {
  return (
    <div
      className={
        "w-full mt-3 flex flex-col items-start gap-4 bg-white sm:rounded pl-5 pr-6 pt-5 pb-6 " +
        className
      }
    >
      <div className="flex flex-row items-center gap-3">
        <TruckIcon style={{ height: "22px", color: "#605BFF" }} />
        <span style={{ color: "#211C52" }} className="font-semibold">
          Pickup
        </span>
      </div>
      <div className="mt-3 pl-1 w-full flex flex-col flex-grow">
        <div className="flex flex-row gap-3">
          <div className="flex flex-col items-center">
            <HouseIcon className="text-gray-800 h-5 w-5 mb-1" />
            <div className="flex-grow border-r-2 border-dashed border-gray-600" />
            <LeftArrowIcon className="h-4 w-4 text-gray-600 transform -rotate-90" />
          </div>
          <div className="pb-7 w-full flex flex-col gap-2">
            {supply_points()?.map((supply, supply_index) => (
              <div
                key={[supply_index]}
                className="w-full flex flex-row items-start gap-x-6 gap-y-3 rounded flex-wrap border px-5 pt-3 pb-4"
              >
                <div className="flex flex-col gap-3 flex-grow">
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {addressFormat(supply?.address)}
                  </span>
                  <div className="">
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Pickup Materials:
                    </span>
                    <div className="mt-2 w-full grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
                      {serviceRequest?.materials
                        ?.filter(
                          (ele) =>
                            ele.method === "PICK_UP" &&
                            supply.supplierId === ele.supplier?.supplierId
                        )
                        ?.map((ele) => (
                          <div className="flex flex-row items-center gap-2">
                            <div className="h-1.5 w-1.5 rounded-full bg-gray-700" />
                            <span
                              style={{
                                color: "#686868",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {ele.name}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {supply.address?.geocodeLatitude &&
                supply.address?.geocodeLongitude ? (
                  <a
                    rel="noreferrer"
                    href={
                      "https://www.google.com/maps/search/?api=1&query=" +
                      supply.address?.geocodeLatitude +
                      "," +
                      supply.address?.geocodeLongitude
                    }
                    target="_blank"
                    className="px-3 py-2 rounded flex flex-row items-center gap-3 text-sm bg-gray-100 text-gray-700 transform hover:scale-105 duration-200 border border-gray-400"
                  >
                    <img
                      alt="Google Map"
                      src="/google_map_link.png"
                      className="h-5 w-5"
                    />
                    Open in Maps
                  </a>
                ) : (
                  <div className="px-3 py-2 mt-3 rounded flex flex-row items-center gap-3 text-sm invisible border">
                    <img
                      als="Google Map"
                      src="/google_map_link.png"
                      className="h-5 w-5"
                    />
                    Open in Maps
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="w-full flex flex-row gap-3">
          <div className="flex flex-col items-center">
            <LocationIcon
              style={{ height: "20px", width: "20px", color: "#605BFF" }}
            />
          </div>
          <div
            style={{ border: "1px solid #605BFF" }}
            className="w-full flex flex-row items-start gap-x-6 gap-y-3 rounded flex-wrap px-5 py-4"
          >
            <div className="flex flex-col gap-4 flex-grow">
              <span className="flex flex-col sm:flex-col gap-y-2">
                <span className="text-gray-600"> Drop Location:</span>{" "}
                {addressFormat(serviceRequest)}
              </span>
            </div>
            {serviceRequest?.geocodeLatitude &&
              serviceRequest?.geocodeLongitude && (
                <a
                  rel="noreferrer"
                  href={
                    "https://www.google.com/maps/search/?api=1&query=" +
                    serviceRequest?.geocodeLatitude +
                    "," +
                    serviceRequest?.geocodeLongitude
                  }
                  target="_blank"
                  className="px-3 py-2 rounded flex flex-row items-center gap-3 text-sm text-gray-700 bg-gray-100 transform hover:scale-105 duration-200 border border-gray-400"
                >
                  <img
                    alt="Google Map"
                    src="/google_map_link.png"
                    className="h-5 w-5"
                  />
                  Open in Maps
                </a>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
