import React from "react";

import { LoadingIcon } from "../common/AppIcons";
import { notificationMarkAsRead } from "../../api/Api";
import { useEffect } from "react";

export default function NewNotifications({
    get_notification,
    screen_size,
    set_main_screen,
    open_notification,
    set_open_notification,
    notifications,
    loading_notification,
    set_loading_notification,
    hide_open_notification,
}) {
    const onClick_notification = (notificationId, link_url) => {
        set_loading_notification(true);
        notificationMarkAsRead({ notificationId })
            .then((res) => {
                set_loading_notification(false);
                console.log(res);
                link_open(link_url)
            })
            .catch((res) => {
                set_loading_notification(false);
                console.log(res);
                link_open(link_url)
            });
    };

    const link_open = (url) => {

        if (!url) return;
        if (url === '/messages' && screen_size === 'lg') {
            set_main_screen('MESSAGE');
            get_notification()
            set_open_notification(false)
        }
        else window.location.href = url;

    }


    return (
        <div
            className={
                "absolute sm:fixed sm:max-w-md w-screen sm:w-full duration-200 z-30 transform translate-x-8 px-6 py-3 " +
                (open_notification ? "" : "hidden")
            }

        >

            {loading_notification ? (
                <div className="overflow-auto w-full flex flex-col items-start gap-2 py-5 bg-white rounded shadow">
                    <span className="font-semibold px-5">Notifications</span>
                    <div className="py-2 m-auto px-5">
                        <LoadingIcon className="h-5 text-blue-400" />
                    </div>
                </div>
            ) : (
                <div className="max-h-96 overflow-auto w-full flex flex-col items-start gap-2 py-4 px-5 bg-white rounded shadow">
                    <span className="font-semibold flex flex-row items-center gap-3">
                        Notifications
                    </span>

                    {notifications?.notifications?.length > 0 ? (
                        <div className="w-full flex flex-col divide-y">
                            {notifications?.notifications?.map((ele, ele_index) => (
                                <a
                                    key={ele_index}
                                    href={ele.link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClick_notification(ele.notificationId, ele.link);
                                    }}
                                    className={
                                        "w-full text-sm flex flex-row gap-2 items-start px-1 py-2 " +
                                        (ele.link ? "hover:bg-gray-100 cursor-pointer " : "") +
                                        (ele.isRead ? "text-gray-400" : "text-gray-700")
                                    }
                                >
                                    <div className="">
                                        <span className="font-semibold">
                                            {ele.title}
                                        </span>
                                        <br />
                                        <span >{ele.content}</span>
                                    </div>
                                </a>
                            ))}
                        </div>
                    ) : (
                        <span className="w-full text-center text-gray-400 text-sm px-5 mt-4 mb-7">
                            No New Notification
                        </span>
                    )}
                    {!hide_open_notification && (
                        <a
                            href="/notifications"
                            className="w-full text-center text-sm text-indigo-500 hover:text-indigo-700 cursor-pointer px-5"
                        >
                            View all notifications
                        </a>
                    )}
                </div>
            )}
        </div>
    );
}
