import React, { useEffect } from "react";

export default function Warning({ warning, set_warning, style, className }) {
    useEffect(() => {
        if (warning) {
            let warning_message = warning?.message;
            setTimeout(() => {
                if (warning?.message === warning_message) set_warning();
            }, 5000);
        }
    }, [warning]);

    return (
        <div className={"w-full h-px overflow-visible flex justify-center " + (warning ? '' : 'hidden')}>
            <div
                style={style}
                className={
                    "fixed bottom-10 z-30 flex flex-row items-center gap-3 px-4 py-2 rounded mx-auto font-semibold text-base text-white bg-red-600 " +
                    className
                }
            >
                <span>{warning}</span>
                <div
                    className="cursor-pointer bg-white bg-opacity-0 hover:bg-opacity-30 py-px px-1 rounded"
                    onClick={() => set_warning()}
                >
                    &#x2715;
                </div>
            </div>
        </div>
    );
}
