import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  BellIcon,
  ChatIcon2,
  LoadingIcon,
  LogoutIcon,
  MessageIcon,
  PersonInCircleIcon,
} from "../common/AppIcons";
import { useEffect } from "react";
import { getNotifications, postFirebaseToken } from "../../api/Api";
import NewNotifications from "../common/NewNotifications";
import WebSocket from "../../utils/WebSocket";

export default function TopButtons({
  userData,
  openUserProfile,
  logOut,
  main_screen,
  set_main_screen,
  screen_size = "sm",
  hide_open_notification,
  className,
}) {
  const [expand_section, set_expand_section] = useState(false);
  const [open_notification, set_open_notification] = useState(false);
  const [notification_alert, set_notification_alert] = useState(false);
  const [notifications, set_notifications] = useState();
  const [loading_notification, set_loading_notification] = useState(true);

  useEffect(() => {
    get_notification();
  }, []);

  const get_notification = () => {
    getNotifications({ size: 3 })
      .then((res) => {
        set_notifications(res);
        if (res?.unreadCount > 0) set_notification_alert(res?.unreadCount);
        else set_notification_alert(false);
        console.log("notifications", res);
        set_loading_notification(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading_notification(false);
      });
  };

  return (
    <div
      className={
        className ?? "z-10 fixed top-2 right-5 flex flex-col items-end m-2"
      }
    >
      <WebSocket
        url={
          userData?.associateId
            ? "/notifications/associate_webapp_notifications/" +
              userData?.associateId
            : null
        }
        onMessage={(msg) => {
          console.log(
            "refreshed from /notifications/associate_webapp_notifications/" +
              userData?.associateId,
            msg
          );
          get_notification();
        }}
        reset_array={[userData?.associateId]}
      />
      <div className="flex flex-row items-center gap-3">
        <a
          className="h-12 w-12 px-3 text-sm font-semibold cursor-pointer text-gray-800 hover:scale-110 bg-white hover:bg-gray-100 rounded-full transform duration-200 flex items-center justify-center "
          href={screen_size === "sm" ? "/messages" : undefined}
          onClick={() => {
            if (screen_size === "lg") {
              set_main_screen(
                main_screen === "MAP" ||
                  main_screen === "SR" ||
                  screen_size === "sm"
                  ? "MESSAGE"
                  : "MAP"
              );
            }
          }}
        >
          {main_screen === "MAP" ||
          main_screen === "SR" ||
          screen_size === "sm" ? (
            <MessageIcon className="h-5" />
          ) : (
            "Map"
          )}
        </a>

        <div className="flex flex-col items-center">
          <div className="h-0 overflow-visible z-20">
            <div
              style={{ backgroundColor: "#605BFF" }}
              className={
                "h-5 w-5 font-semibold text-xs flex items-center justify-center rounded-full text-white transform translate-x-3 " +
                (notification_alert ? "" : "hidden")
              }
            >
              {notification_alert > 99 ? "99+" : notification_alert}
            </div>
          </div>
          <button
            className="h-12 w-12 px-3 text-sm font-semibold cursor-pointer text-gray-800 hover:scale-110 bg-white hover:bg-gray-100 rounded-full transform duration-200 flex items-center justify-center focus:outline-none "
            onClick={() => {
              set_open_notification((open_notification) => !open_notification);
            }}
          >
            <BellIcon className="h-5" />
          </button>
        </div>

        <div
          className={
            "bg-white hover:bg-gray-100 rounded-full transform duration-200 flex items-center justify-center " +
            (expand_section ? "h-12 w-52" : "h-12 w-12 cursor-pointer")
          }
          onMouseLeave={() => {
            if (screen_size === "lg") set_expand_section(false);
          }}
          onMouseEnter={() => {
            if (screen_size === "lg") set_expand_section(true);
          }}
          onClick={() => {
            if (screen_size === "sm")
              set_expand_section((expand_section) => !expand_section);
          }}
          onBlur={() => {
            set_expand_section(false);
          }}
          tabIndex={0}
        >
          {expand_section ? (
            <div
              onClick={(e) => e.stopPropagation()}
              className="w-full flex flex-row items-center overflow-hidden"
            >
              <div
                onClick={openUserProfile}
                className="w-full flex flex-row items-center text-xs p-2 hover:bg-gray-200 rounded-l-full cursor-pointer duration-200"
              >
                <PersonInCircleIcon className="h-2 transform scale-50" />{" "}
                Account
              </div>
              <div
                onClick={logOut}
                className="w-full flex flex-row items-center whitespace-pre text-xs p-2 hover:bg-gray-200 rounded-r-full cursor-pointer duration-200"
              >
                <LogoutIcon className="h-2 transform scale-50" /> Log out
              </div>
            </div>
          ) : (userData?.firstName ?? "") + (userData?.lastName ?? "") ===
            "" ? (
            <FontAwesomeIcon icon={faUser} className="text-xl" />
          ) : (
            <span className="text-lg font-semibold">
              {(
                (userData?.firstName ?? "") + (userData?.lastName ?? "")
              ).charAt(0)}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <NewNotifications
          get_notification={get_notification}
          screen_size={screen_size}
          set_main_screen={set_main_screen}
          open_notification={open_notification}
          set_open_notification={set_open_notification}
          notifications={notifications}
          loading_notification={loading_notification}
          set_loading_notification={set_loading_notification}
          hide_open_notification={hide_open_notification ?? false}
        />
      </div>
    </div>
  );
}
