import React, { useEffect, useRef } from "react";
import { CrossIcon, DeleteIcon, CloudIcon } from "./AppIcons";
import { useDropzone } from "react-dropzone";

const fileCountBar = (fileCount) => {
  switch (fileCount) {
    case 0:
      return "w-0";
    case 1:
      return "w-1/5 bg-green-400";
    case 2:
      return "w-2/5 bg-green-400";
    case 3:
      return "w-3/5 bg-green-400";
    case 4:
      return "w-4/5 bg-yellow-400";
    case 5:
      return "w-full bg-red-400";
    default:
      return "";
  }
};

export default function MediaUpload({
  value,
  onChange,
  open_popup,
  className,
}) {
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length + value.length > 5) {
      if (value.length !== 5) {
        onchange([...value, ...acceptedFiles.slice(0, 5 - value.files.length)]);
      }
    } else {
      onChange([...value, ...acceptedFiles]);
    }
    console.log(value);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={
        "bg-gray-100 rounded hover:bg-gray-100 cursor-pointer border-none border-gray-300 flex flex-col items-center justify-center gap-3 py-2 " +
        (isDragActive ? "bg-gray-200 " : "") +
        className
      }
    >
      <div className="mt-3 w-full px-3">
        <div className="h-1 w-full rounded-full flex items-center bg-gray-300">
          <div
            className={
              "h-1 duration-300 delay-100 " + fileCountBar(value.length)
            }
          />
        </div>
      </div>
      <input {...getInputProps()} />
      {value[0] ? (
        <div className="flex flex-row gap-1 p-1 items-start flex-wrap justify-center py-5 ">
          {value.map((file, index) => {
            return (
              <div
                key={index}
                className="flex flex-col border border-gray-300 bg-gray-200 text-sm opacity-80 p-2 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-row items-start gap-2 ">
                  <DisplayMedia
                    file={file}
                    index={index}
                    onDelete={() => {
                      let arr = value;
                      arr.splice(index, 1);
                      onChange(arr);
                    }}
                    open_popup={open_popup}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-2 px-3 md:px-10 ">
          <a className="font-semibold opacity-60 text-center ">
            Maximum of 5 files
          </a>
          <CloudIcon className="h-5 mt-4 transform scale-125" />
          <a className="mt-1 font-semibold opacity-60 text-center ">
            Drag and drop to upload or
          </a>
        </div>
      )}
      <button className="border border-blue-200 text-blue-600 flex items-center transition delay-100 duration-200 hover:bg-blue-400 hover:text-white rounded-md mb-3 p-2 px-5 ">
        Browse Files
      </button>
    </div>
  );
}

function DisplayMedia({ file, index, onDelete, open_popup }) {
  if (file.type.split("/")[1] === "heic")
    return (
      <div
        key={index}
        onClick={() => open_popup(index)}
        className="cursor-pointer flex flex-row gap-2 hover:opacity-60"
      >
        <div className="flex flex-col gap-2 items-start">
          <img className="h-24 object-cover" src="/placeholder.png" />
          <span className="text-sm text-gray-700">{file.name}</span>
        </div>
        <div className="flex items-start" onClick={(e) => e.stopPropagation()}>
          <div className="cursor-pointer" onClick={onDelete}>
            <DeleteIcon className="h-5 w-5 text-red-500 hover:text-red-800" />
          </div>
        </div>
      </div>
    );
  if (file.type.split("/")[0] === "image")
    return (
      <div
        key={index}
        onClick={() => open_popup(index)}
        className="cursor-pointer flex flex-row gap-2 hover:opacity-60"
      >
        <img className="h-24 object-cover" src={URL.createObjectURL(file)} />
        <div className="flex items-start" onClick={(e) => e.stopPropagation()}>
          <div className="cursor-pointer" onClick={onDelete}>
            <DeleteIcon className="h-5 w-5 text-red-500 hover:text-red-800" />
          </div>
        </div>
      </div>
    );
  if (file.type.split("/")[0] === "video")
    return (
      <div
        key={index}
        onClick={() => open_popup(index)}
        className="cursor-pointer flex flex-row gap-2 hover:opacity-60"
      >
        <video className="h-24" controls>
          <source src={URL.createObjectURL(file)} type={file.type} />
          Your browser does not support HTML5 video.
        </video>
        <div className="flex items-start" onClick={(e) => e.stopPropagation()}>
          <div className="cursor-pointer" onClick={onDelete}>
            <DeleteIcon className="h-5 w-5 text-red-500 hover:text-red-800" />
          </div>
        </div>
      </div>
    );
  else if (file.type.split("/")[0] === "application")
    return (
      <div
        key={index}
        onClick={() => open_popup(index)}
        className="cursor-pointer flex flex-row justify-between gap-8 w-full px-4 py-2 border shadow bg-gray-100 hover:opacity-60"
      >
        {file.name}
        <div onClick={(e) => e.stopPropagation()}>
          <div className="cursor-pointer" onClick={onDelete}>
            <DeleteIcon className="h-5 w-5 text-red-500 hover:text-red-800" />
          </div>
        </div>
      </div>
    );
  else return null;
}
