import React, { useState, useEffect } from "react";
import { LeftArrowIcon, LoadingIcon, PaperClipIcon, SentIcon } from "../common/AppIcons";
import MessageDisplay from "./MessageDisplay";
import {
    getDashboardMessages,
    getUserInfo,
    postDashboardMessages,
} from "../../api/Api";
import WebSocket from "../../utils/WebSocket";

const base_client_data = {
    online: true,
    name: "Help Squad",
    profile_pic: null,
};

const date_format = (date) => {
    var today = new Date(date);

    if (!date) return undefined;

    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "-" + dd + "-" + yyyy;
};

export default function ChatComponent({
    component_className, go_back
}) {
    const [client_data, set_client_data] = useState(base_client_data);
    const [chats, set_chats] = useState([]);
    const [new_input_message, set_new_input_message] = useState({
        message: "",
        attachmentsList: [],
        flag: false,
    });
    const [load_chat_params, set_load_chat_params] = useState({
        page_number: 0,
        page_size: 10,
        all_chat_loaded: false,
        loading_chat: true,
        message_senting: false,
    });
    const [flag, set_flag] = useState(false);
    const [associateData, setAssociateData] = useState({});
    const [height, set_height] = useState()

    useEffect(() => {
        function updateSize() {
            set_height(window?.innerHeight)
        }
        window.addEventListener('resize', updateSize);
        setTimeout(() => { updateSize(); }, 200)
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    useEffect(() => {
        set_height(window?.innerHeight)
    }, [chats])

    useEffect(() => {
        getUserInfo()
            .then((data) => {
                setAssociateData(data);
                console.log("user Data Received from Server", data);
            })
            .catch((data) => {
                console.log("error", data);
            });
    }, []);


    useEffect(() => {
        get_messages();
    }, [load_chat_params.page_number]);

    useEffect(() => {
        if (
            !new_input_message.flag &&
            new_input_message.attachmentsList &&
            new_input_message.attachmentsList.length > 0
        ) {
            set_new_input_message((set_new_input_message) => {
                return { ...new_input_message, flag: true };
            });
            sent_message();
        }
    }, [new_input_message.attachmentsList]);

    const sent_message = () => {
        set_flag(false);
        if (new_input_message.message?.trim() !== '' || new_input_message.attachmentsList) {
            set_load_chat_params({ ...load_chat_params, message_senting: true });

            postDashboardMessages({
                data: new_input_message
            })
                .then((res) => {
                    console.log({ res });
                    load_chat_params.page_number === 0 ?
                        get_messages(0) : set_load_chat_params({ ...load_chat_params, page_number: 0 })
                    set_new_input_message({});
                })
                .catch((res) => {
                    console.log({ res });
                    set_new_input_message({});
                    set_load_chat_params({
                        ...load_chat_params,
                        message_senting: false,
                    });
                });
        }
    };

    const get_messages = (page) => {
        set_flag(false);
        set_load_chat_params((load_chat_params) => {
            return { ...load_chat_params, loading_chat: true };
        });

        getDashboardMessages({
            data: {
                page: page ?? load_chat_params.page_number,
                size: load_chat_params.page_size,
            }
        })
            .then((res) => {
                let arr = [...res];
                set_chats((chats) => {
                    return [
                        ...chats,
                        ...arr.filter((val) => {
                            return chats.find(
                                (val2) => val2.communicationId === val.communicationId
                            )
                                ? false
                                : true;
                        }),
                    ];
                });
                console.log({ res });

                set_load_chat_params({
                    ...load_chat_params,
                    loading_chat: false,
                    message_senting: false,
                    all_chat_loaded:
                        res.totalPages === load_chat_params.page_number ? true : false,
                });

                setTimeout(() => {
                    if (page === 0) document?.getElementById('last_message')?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                }, 200)
            })
            .catch((res) => {
                console.log({ res });
                set_load_chat_params((load_chat_params) => {
                    return { ...load_chat_params, loading_chat: false };
                });
            });
    };

    return (
        <div style={{ height: height }} className="h-full w-full bg-gray-200 overflow-hidden flex justify-center">
            <WebSocket
                url={associateData?.associateId ? "/notifications/associate_webapp_message/" + associateData?.associateId : null}
                onMessage={(msg) => {
                    console.log(
                        "refreshed from /notifications/dashboard_message/" +
                        associateData?.associateId,
                        msg
                    );
                    load_chat_params.page_number === 0
                        ? get_messages(0)
                        : set_load_chat_params({ ...load_chat_params, page_number: 0 });
                }}
                reset_array={[associateData?.associateId]} />
            <div className={"absolute max-w-4xl w-full h-full bg-white rounded border shadow-xl flex flex-col justify-between " + component_className}>
                {/* top bar */}
                <div
                    className=" bg-white w-full mb-auto flex flex-row justify-between p-4 shadow-lg border-b border-gray-300">
                    <div className="flex flex-row items-center gap-2 md:gap-3">
                        <a
                            href={go_back ? undefined : '/'}
                            className="text-gray-600 hover:opacity-80 flex cursor-pointer items-center"
                            onClick={go_back}
                        >
                            <LeftArrowIcon className="w-6 md:w-7" />
                        </a>
                        <div className="flex flex-col">
                            {client_data.profile_pic ? (
                                <img
                                    className="h-9 w-9 rounded-full object-cover"
                                    src={client_data.profile_pic}
                                    alt="profile"
                                />
                            ) : (
                                <div className="h-12 w-12 flex items-center justify-center text-2xl font-bold bg-white border shadow text-indigo-600 rounded-full">
                                    {client_data.name?.trim().charAt(0)}
                                </div>
                            )}
                            <div
                                className={
                                    "h-1 w-1 absolute rounded-full transform scale-125 translate-x-10 translate-y-10 " +
                                    (client_data.online ? "bg-green-500" : "bg-red-500")
                                }
                            />
                        </div>
                        <div className="flex flex-col ml-1">
                            <span className="font-semibold text-xl text-gray-700">
                                {client_data.name ?? ""}
                            </span>
                        </div>
                    </div>
                </div>

                {/* chat display section */}
                <div
                    className="flex flex-grow overflow-auto max-h-full bg-gray-100">
                    <MessageDisplay
                        client_data={client_data}
                        chats={chats?.sort((obj1, obj2) => {
                            return new Date(obj1.createdAt) - new Date(obj2.createdAt);
                        })}
                        load_chat_params={load_chat_params}
                        set_chats={set_chats}
                        call_next_page={() => {
                            set_load_chat_params({
                                ...load_chat_params,
                                page_number: load_chat_params.page_number + 1,
                            });
                        }}
                        flag={flag}
                        set_flag={set_flag}
                        date_format={date_format}
                    />
                </div>

                {/* bottom bar */}
                <div
                    className="bg-white px-4 py-3 shadow border-t border-gray-300 flex flex-row items-center">
                    <div
                        onClick={() => document.getElementById("file_input").click()}
                        className="hover:bg-gray-100 p-2 rounded-full cursor-pointer"
                    >
                        <input
                            type="file"
                            multiple
                            onChange={(e) => {
                                set_load_chat_params({
                                    ...load_chat_params,
                                    message_senting: true,
                                });
                                let temp_arr = [];
                                [...e.target.files].map((file, index, arr) => {
                                    let reader = new FileReader();
                                    reader.onloadend = function () {
                                        temp_arr = [
                                            ...temp_arr,
                                            {
                                                fileName: file.name,
                                                contentType: file.type,
                                                encodedFile: reader.result,
                                            },
                                        ];
                                        if (arr.length - 1 === index) {
                                            set_new_input_message({
                                                ...new_input_message,
                                                attachmentsList: temp_arr,
                                            });
                                        }
                                    };
                                    reader.readAsDataURL(file);
                                });
                            }}
                            className="hidden"
                            id="file_input"
                        />

                        <PaperClipIcon className="h-6 w-6 text-gray-500" />
                    </div>

                    <input
                        onKeyDown={(e) => {
                            if (e.key === "Enter") sent_message();
                        }}
                        value={new_input_message.message ?? ""}
                        onChange={(e) =>
                            set_new_input_message({
                                ...new_input_message,
                                message: e.target.value,
                            })
                        }
                        placeholder="Type a message..."
                        className="flex flex-grow text-gray-700 outline-none focus:outline-none bg-white rounded px-2 py-1"
                    />
                    <div
                        onClick={sent_message}
                        className="hover:bg-gray-100 p-2 ml-2 rounded-full cursor-pointer"
                    >
                        {load_chat_params.message_senting ? (
                            <LoadingIcon style={{ height: '22px' }} className="h-4 w-4 text-blue-500" />
                        ) : (
                            <SentIcon className="h-6 w-6 text-blue-500" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
