export const addressFormat = (address, className) =>
  address ? (
    <div className={"flex flex-col " + className}>
      <span>{address.streetAddress1 ? address.streetAddress1 + "," : ""}</span>
      <span>
        {address.unitNumber ?? address.streetAddress2 ?? ""}
        {address.unitNumber || address.streetAddress2 ? "," : ""}
      </span>
      <span>
        {(address.city ? address.city + ", " : "") +
          (address.state ?? "") +
          " " +
          (address.zip ?? "")}
      </span>
    </div>
  ) : (
    ""
  );

export const addressFormatLine = (address) =>
  address
    ? (address.streetAddress1 ? address.streetAddress1 : "") +
      (address.streetAddress2 || address.unitNumber
        ? ", " + (address.unitNumber ?? address.streetAddress2)
        : "") +
      (address.city ? ", " + address.city : "")
    : "";
