import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "firebase/compat/messaging";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from "firebase/compat/app";

import { postFirebaseToken } from "../../api/Api";
import { ToastMessage } from "./ToastMessage";

const firebaseConfig = {
  apiKey: "AIzaSyDo89RMfMzwhPKtBNOA7WqEz-npFHnsfno",
  authDomain: "helpsquad-notification.firebaseapp.com",
  databaseURL: "https://helpsquad-notification.firebaseio.com",
  projectId: "helpsquad-notification",
  storageBucket: "helpsquad-notification.appspot.com",
  messagingSenderId: "297186053866",
  appId: "1:297186053866:web:ceb55a02dc929086c75193",
  measurementId: "G-NG7MGN43HH",
};

const app = initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported() ? getMessaging(app) : null;

export const requestForToken = () =>
  firebase.messaging.isSupported()
    ? getToken(messaging, {
        vapidKey:
          "BI4NIAOywge2G3Wqj22isKKM25XJbXkT62PTcV7uJQGBZupaAbmNb4lvkBQUwREO_2urvZ53rWuVQPc7M2QaNHw",
      })
    : null;

// fetching firebase token
export const fetchFirebaseToken = () => {
  if (firebase.messaging.isSupported()) {
    console.log("fetching firebase token");

    requestForToken()
      .then((firebaseToken) => {
        console.log({ firebaseToken });
        post_firebase_token(firebaseToken);
        if (!firebaseToken)
          console.log(
            "No registration token available. Request permission to generate one."
          );
      })
      .catch((err) => {
        console.log({ err });
      });
  } else {
    toast("Firebase notification is not supported for this browser");
    console.log("firebase notification not supported in this browser");
  }
};

// senting firebase token to backend
const post_firebase_token = (firebaseToken) => {
  postFirebaseToken({ firebaseToken })
    .then((res) => {
      console.log(res);
    })
    .catch((res) => {
      console.log(res);
      toast(res.message ?? "firebase error");
    });
};

// on receiving message
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);

      new Audio("/firebase_push_notification_foreground.wav")?.play();
      resolve(payload);
      toast.info(<ToastMessage payload={payload} />, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  });

export default function FirebaseNotification() {
  // calling message arrive listner
  firebase.messaging.isSupported() &&
    onMessageListener()
      .then((payload) => {
        console.log("new message received", payload);
      })
      .catch((err) => {
        console.log(err);
      });

  return (
    <ToastContainer
      position="top-center"
      autoClose={10000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
}
