import React, { useState } from "react";
import { updateAppointmentStatus } from "../../../api/Api";
import Warning from "../../common/Warning";
import { LoadingIcon } from "../../common/AppIcons";

export default function UpdateStatusToAccepted({
  on_close,
  on_success,
  appointmentId,
  update_status,
}) {
  const [warning, set_warning] = useState();
  const [loading, set_loading] = useState(false);

  const update_apponitment_status = () => {
    set_loading(true);
    updateAppointmentStatus({ appointmentId, status: update_status })
      .then((res) => {
        console.log({ res });

        set_loading(false);
        on_success();
      })
      .catch((res) => {
        console.log({ res });
        set_warning(res.readableMessag ?? res.message ?? res.error ?? "Error ");
        set_loading(false);
      });
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full w-full">
        <LoadingIcon className="h-12 text-blue-600" />
      </div>
    );
  else
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-center bg-white max-w-xl w-full p-7 rounded shadow-md"
      >
        <Warning {...{ warning, set_warning }} />
        <span className="font-semibold">
          {update_status === "ACCEPTED"
            ? "Accept Appointment?"
            : "Reject appointment?"}
        </span>
        <div className="mt-12 w-full flex flex-col-reverse sm:flex-row sm:justify-around gap-5 max-w-xs mx-auto">
          <button
            className="rounded px-12 py-2 text-indigo-500 bg-indigo-100 focus:outline-none hover:opacity-80 font-semibold"
            onClick={on_close}
          >
            Cancel
          </button>
          <button
            className="rounded px-12 py-2 bg-indigo-500 text-indigo-100 hover:opacity-80"
            onClick={update_apponitment_status}
          >
            {update_status === "ACCEPTED" ? "Accept" : "Reject"}
          </button>
        </div>
      </div>
    );
}
