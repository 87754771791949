import { useState } from "react";
import { CalenderIcon3, ClockIcon2, LoadingIcon } from "./AppIcons";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Input1({
  id,
  label,
  value,
  onChange,
  type = "",
  className = "",
  options,
  optionsLoading,
  optionOnClick,
  onMouseEnter,
  onMouseLeave,
}) {
  const [inputFocus, setInputFocus] = useState();
  const [inputHover, set_inputHover] = useState();

  return (
    <div
      className={
        "text-gray-800 hover:text-blue-800 " +
        (inputFocus ? "text-blue-800 " : " ") +
        className
      }
    >
      <span
        className={
          "font-medium duration-300 whitespace-nowrap " +
          (inputFocus ? "text-sm" : "text-xs")
        }
      >
        {label}
      </span>
      {type === "phone" ? (
        <PhoneInput
          country={"us"}
          value={value ?? ""}
          onChange={(phone) => {
            onChange(phone);
          }}
          dropdownStyle={{ maxHeight: "160px" }}
          inputProps={{
            className:
              "pl-12 focus:outline-none focus:border-none focus:ring-0 border-none bg-gray-200 hover:bg-gray-300 focus:bg-gray-300 mt-px pl-2 rounded w-full text-gray-700",
          }}
        />
      ) : type === "drop_down" ? (
        <select
          value={value ?? "DEFAULT"}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className="focus:outline-none focus:border-none focus:ring-0 border-none bg-gray-200 hover:bg-gray-300 focus:bg-gray-300 mt-px px-2 rounded w-full text-gray-700"
        >
          <option value="DEFAULT" disabled hidden>
            Select
          </option>
          {options.map((option, option_index) => (
            <option
              className="bg-white hover:bg-gray-200"
              key={option_index}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <div className="flex flex-col relative">
          <form
            onMouseEnter={() => {
              set_inputHover(true);
              onMouseEnter && onMouseEnter();
            }}
            onMouseLeave={() => {
              set_inputHover(false);
              onMouseLeave && onMouseLeave();
            }}
            className={
              "relative w-full flex flex-row items-center gap-1 text-gray-700 rounded " +
              (inputHover ? "bg-gray-300" : "bg-gray-200")
            }
          >
            <input
              id={id}
              value={value ?? ""}
              type={type}
              onChange={(e) => onChange(e.target.value)}
              className={
                "px-5 py-2 focus:outline-none focus:border-none focus:ring-0 border-none w-full font-medium " +
                (inputHover ? "bg-gray-300" : "bg-gray-200")
              }
              onFocus={() => {
                setInputFocus(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setInputFocus(false);
                }, 200);
              }}
            />
            <label
              htmlFor={"input" + id}
              className={
                "absolute right-6 top-1.5 pointer-events-none " +
                (inputHover ? "bg-gray-300" : "bg-gray-200")
              }
            >
              {type === "time" ? (
                <ClockIcon2 className="h-5" />
              ) : type === "date" ? (
                <CalenderIcon3 className="" style={{ height: "21px" }} />
              ) : null}
            </label>
          </form>

          {options && inputFocus && (optionsLoading || options?.length > 0) && (
            <div className="top-10 absolute z-10 bg-white border w-full max-h-96 overflow-auto">
              <div className="flex flex-col w-full">
                {optionsLoading && (
                  <div
                    className={"flex items-center justify-center w-full py-2"}
                  >
                    <LoadingIcon
                      className="text-blue-400"
                      style={{ height: "17px" }}
                    />
                  </div>
                )}
                {options?.map((option, option_index) => (
                  <span
                    className="w-full cursor-pointer truncate text-gray-700 px-4 py-2 hover:bg-gray-100"
                    key={option_index}
                    onClick={() => {
                      optionOnClick
                        ? optionOnClick(option.value)
                        : onChange(option.value);
                    }}
                  >
                    {option.label}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
