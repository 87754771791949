import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActiveUserStore from "../../store/ActiveUserStore";
import {
  faThLarge,
  faColumns,
  faListAlt,
  faCog,
  faComment,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { A } from "hookrouter";
import squadhub from "../../assets/squadhub.png";

export default function LeftSidebar({ toggleSidebar, className = "" }) {
  return (
    <div
      className={
        "fixed z-10 inset-0 bg-gray-700 bg-opacity-40 lg:static " + className
      }
      onClick={() => {
        toggleSidebar();
      }}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          toggleSidebar();
        }
      }}
    >
      <div
        id="sideBar"
        tabIndex="-1"
        className="float-left inset-y-0 flex flex-shrink-0 bg-white relative focus:outline-none shadow-2xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* Mini column */}
        <nav className="flex flex-col  flex-shrink-0 h-screen pl-4 pr-3 lg:pr-0 lg:pl-0  py-8 border-r-2 border-gray-300">
          {/* Brand */}

          <span className="sr-only">User</span>
          <span className=" cursor-pointer rounded-full float-right w-28 h-28  p-3 ">
            <img src={squadhub} alt="logo"></img>
          </span>
          <div className="flex flex-col items-center justify-between mt-8 h-full flex-1 ">
            {[
              { text: "Home", icon: faThLarge },
              { text: "Messages", icon: faComment },
              { text: "Another Link", icon: faColumns },
              { text: "Users", icon: faListAlt },
              {
                text: "Logout",
                icon: faSignOutAlt,
                onClick: (_) => {
                  console.log("Logging Out");
                  ActiveUserStore.logoutUser();
                },
              },
              {
                text: "settings",
                icon: faCog,
                class: "text-5xl",
              },
            ].map((nav) => (
              <A
                href="#"
                className="p-3 transition-colors duration-200  rounded-full  dark:hover:text-light dark:hover:bg-indigo-700 dark:bg-dark focus:outline-none focus:bg-indigo-100 dark:focus:bg-indigo-700 focus:ring-indigo-800"
                onClick={nav.onClick}
                key={nav.text}
              >
                <span className="sr-only">{nav.text}</span>
                <FontAwesomeIcon
                  icon={nav.icon}
                  className={
                    "stroke-1 md:stroke-2 text-3xl hover:text-gray-500 text-gray-900 " +
                    nav.class
                  }
                />
              </A>
            ))}
          </div>
          {/* Sidebar footer */}
          {/* prevent default just to prevent form submission in this demo  */}
        </nav>
      </div>
    </div>
  );
}
