export const findFileTypeFromURL = (url) => {
  let file =
    url.split(".").length > 0
      ? url.split(".")[url.split(".").length - 1]
      : null;

  if (
    [
      "png",
      "jpeg",
      "gif",
      "bmp",
      "jpg",
      "apng",
      "svg",
      "bmp ico",
      "png ico",
    ].includes(file)
  )
    return "image";
  else if (
    [
      // 'heic',
      "mp4",
      "webm",
      "ogg",
      "mov",
      "mkv",
      "flv",
      "avi",
      "wmv",
      "amv",
      "3gp",
    ].includes(file)
  )
    return "video";
  else if (["mp3", "aac", "m4a", "flac", "wav", "wma"].includes(file))
    return "audio";

  return false;
};
