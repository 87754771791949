import React, { useState } from "react";
import LeftSidebar from "../dashboard/LeftSidebar";
import TopBar from "../dashboard/TopBar";

export default function AppLayout({ children, togglePopup }) {
  const [isSidebarOpen, changeIsSidebarOpen] = useState(false);

  const [loadingHide] = useState("hidden");

  const toggleSidebar = () => {
    changeIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen overflow-x-hidden flex items-center justify-center">
      <div className="flex h-screen w-full antialiased text-gray-900 dark:bg-dark dark:text-light">
        {/* Loading screen */}
        <div
          id="loading"
          className={
            "fixed inset-0 z-50 flex items-center justify-center text-2xl font-semibold text-white bg-indigo-800 " +
            loadingHide
          }
        >
          Loading.....
        </div>
        {/* Sidebar first column */}
        {/* Backdrop */}

        {/* <Transition
            show={isSidebarOpen}
            enter="transition-all transform duration-100 ease-in-out"
            enterFrom="-translate-x-full opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition-all transform duration-100 ease-in-out"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-full opacity-0"
            className={
              isSidebarOpen ? "float-left sticky" : "float-left hidden lg:sticky"
            }
          > */}
        <div
          className={
            isSidebarOpen ? "float-left " : "float-left hidden lg:block"
          }
        >
          <LeftSidebar toggleSidebar={toggleSidebar} className="hidden" />
        </div>
        {/* </Transition> */}

        {/* Left bar done. Writing rest of it */}

        <div
          className={
            "float-right transition-all overflow-hidden transform duration-600 w-full"
          }
        >
          {/* middle section */}

          {/* Sidebar button */}
          {/* <Transition
              show={!isSidebarOpen}
              enter="transition-all transform duration-500 ease-in-out"
              enterFrom="-translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              leave="transition-all transform duration-500 ease-in-out"
              leaveFrom="translate-x-0 opacity-100 "
              leaveTo="-translate-x-full opacity-0"
            > */}
          {/* <div className="block lg:hidden">
            <TopBar toggleSidebar={toggleSidebar} togglePopup={togglePopup} />
          </div> */}
          {children}
        </div>
      </div>
    </div>
  );
}
