import React, { useState, useEffect } from "react";
import { recoverPassword, verifyRecovery } from "../../api/Api";
import { toast } from "react-toastify";
import { navigate } from "hookrouter";

export default function RecoverPassword({ uuid, email }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [valid, setValid] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const payload = {
      email: email,
      uuid: uuid,
    };
    verifyRecovery(payload)
      .then((response) => {})
      .catch((error) => {
        setValid(false);
        toast.error("Invalid Password Recovery Link");
      });
  }, []);

  const checkFields = () => {
    if (password === "") {
      setErrorMessage("Please enter new password");
    } else if (confirmPassword === "") {
      setErrorMessage("Please enter confirm password");
    } else if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
      return true;
    }
    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let fieldsValid = checkFields();
    if (fieldsValid) {
      toast.info("Please Wait!");
      const payload = {
        email: email,
        password: password,
        uuid: uuid,
      };
      recoverPassword(payload)
        .then((response) => {
          toast.success("You can now login with your new Password!");
          navigate("/");
        })
        .catch((error) => {
          toast.error("Sorry, We were unable to complete your request");
        });
    }
  };

  return valid ? (
    <div className="mx-auto max-w-xl p-20">
      <span className="text-gray-700 text-xl">Recover Password</span>
      <form className="py-2" noValidate onSubmit={handleSubmit}>
        <div className="flex flex-row border-solid border border-gray-500 mt-4 rounded-md">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            id="newPassword"
            name="newPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={checkFields}
            required
            className="flex-1 bg-white border-0 rounded-md px-2 py-2 focus:outline-none"
          />
          {showPassword === false ? (
            <button
              type="button"
              className="text-gray-600 py-2 rounded-r-md focus:outline-none"
              onClick={() => setShowPassword(true)}
            >
              <svg
                className="flex h-6 w-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </button>
          ) : (
            <button
              type="button"
              className="text-gray-600 w-2/12 py-2 rounded-r-md focus:outline-none"
              onClick={() => setShowPassword(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </svg>
            </button>
          )}
        </div>
        <input
          type="password"
          placeholder="Re-enter new password"
          id="confirmPassword"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={checkFields}
          required
          className="bg-white w-full mt-4 px-2 py-2 border-solid border border-gray-500 rounded-md focus:outline-none"
        />

        {errorMessage !== "" && (
          <div className="text-sm text-red-500 mt-4">{errorMessage}</div>
        )}
        <button className="bg-green-600 text-gray-100 hover:text-blue-400 rounded-md px-2 py-1 mt-4 focus:outline-none">
          Set New Password
        </button>
      </form>
    </div>
  ) : (
    <div className="mx-auto max-w-xl p-20">
      <span className="text-gray-700 text-xl">
        Invalid Password Recovery Link. Please try again.
      </span>
    </div>
  );
}
