import React, { useReducer, useState } from "react";
import { convertTimezone } from "../../utils/convertTimezone";
import {
  CalenderIcon,
  LeftArrowIcon,
  LeftArrowIcon2,
  RightArrowIcon,
} from "../common/AppIcons";

const DAYS = ["S", "M", "T", "W", "T", "F", "S"];
const themes = [
  {
    bg: "bg-blue-400",
    date: "bg-blue-500",
  },
  {
    bg: "bg-red-400",
    date: "bg-red-500",
  },
  {
    bg: "bg-yellow-400",
    date: "bg-yellow-500",
  },
  {
    bg: "bg-green-400",
    date: "bg-green-500",
  },
  {
    bg: "bg-purple-400",
    date: "bg-purple-500",
  },
];

// const eventStyler = (theme) => {
//   if (theme === "blue") return "border-blue-200 text-blue-800 bg-blue-100";
//   if (theme === "red") return "border-red-200 text-red-800 bg-red-100";
//   if (theme === "yellow")
//     return "border-yellow-200 text-yellow-800 bg-yellow-100";
//   if (theme === "green")
//     return "border-green-200 text-green-800 bg-green-100";
//   if (theme === "purple")
//     return "border-purple-200 text-purple-800 bg-purple-100";
// };

function reducer(state, action) {
  let date = new Date(state);
  switch (action.type) {
    case "INCREASE_MONTH":
      date.setMonth(date.getMonth() + 1);
      return date;
    case "DECREASE_MONTH":
      date.setMonth(date.getMonth() - 1);
      return date;
    default:
      throw new Error();
  }
}
const getBlankDays = (state) => {
  let month = new Date(state.getFullYear(), state.getMonth(), 1);
  let arr = [];
  let prevMonth = new Date(new Date(state.getFullYear(), state.getMonth(), 1));
  prevMonth.setDate(prevMonth.getDate() - 1);
  for (let i = 0; i < month.getDay(); i++) {
    arr = [prevMonth.getDate(), ...arr];
    prevMonth.setDate(prevMonth.getDate() - 1);
  }
  return arr;
};

const getDays = (state, appointments) => {
  let nextMonth = new Date(new Date(state.getFullYear(), state.getMonth(), 1));
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  nextMonth.setDate(nextMonth.getDate() - 1);

  let days = [];
  let events = appointments.map((data) => {
    return {
      start: Number(
        convertTimezone(data.timelineStart, data.timezone).getDate()
      ),
    };
  });
  for (let i = 1; i <= nextMonth.getDate(); i++) days = [...days, []];

  // events.map((value, index) => {
  //   let period = value.end - value.start;
  //   for (let j = 1; j < period; j++) days[value.start + j - 1] = { middle: themes[index].bg };
  // })
  for (let i = 0; i < events.length; i++) {
    if (days[events[i].start - 1])
      days[events[i].start - 1] = [
        ...days[events[i].start - 1],
        themes[i % 5].date,
      ];
  }
  return days;
};

const dateStyler = (today) => {
  return today ? "border border-blue-500 " : "";
};
const isToday = (date, state) => {
  const stateDate = new Date(
    new Date(state.getFullYear(), state.getMonth(), date)
  );
  return new Date(new Date()).toDateString() === stateDate.toDateString();
};

// washington dc time - new Date().setTime(new Date().getTime() - (1000 * 60 * (new Date().getTimezoneOffset() + 360)))

export default function Calendar({
  monthIncrease,
  monthDecrease,
  appointments,
  onSelectCB = () => {},
  date_selected,
}) {
  const [state, dispatch] = useReducer(reducer, new Date());
  const [open_calender, set_open_calender] = useState(true);

  return (
    <div className="antialiased sans-serif flex justify-center w-full h-full shFow">
      <div className="w-full h-full">
        {/*  Schedule Tasks*/}
        {/* {JSON.stringify(baseData)} */}
        <div className="bg-white rounded-lg px-1 md:px-3 py-4">
          <div className="flex items-center justify-between px-4">
            <div className="font-semibold text-gray-800 flex flex-row items-center">
              <span>
                {state.toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                  month: "long",
                })}
              </span>
              <span className="ml-2">{state.getFullYear()}</span>
            </div>
            <div className="flex flex-row items-center gap-1">
              {[
                {
                  onClick: () => {
                    monthDecrease();
                    dispatch({ type: "DECREASE_MONTH" });
                  },
                  icon: (
                    <LeftArrowIcon2 className="h-4 w-4 transform scale-50 text-gray-500 inline-flex leading-none" />
                  ),
                },
                {
                  onClick: () => {
                    monthIncrease();
                    dispatch({ type: "INCREASE_MONTH" });
                  },
                  icon: (
                    <LeftArrowIcon2 className="h-4 w-4 transform scale-50 text-gray-500 inline-flex leading-none transform rotate-180" />
                  ),
                },
              ].map((element, index) => (
                <button
                  key={index}
                  className={
                    "leading-none rounded-lg transform translate-y-px transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 focus:outline-none items-center"
                  }
                  onClick={element.onClick}
                >
                  {element.icon}
                </button>
              ))}
              <div
                onClick={() =>
                  set_open_calender((open_calender) => !open_calender)
                }
                className={
                  "ml-2 hover:opacity-60 cursor-pointer " +
                  (open_calender ? "text-indigo-500" : "")
                }
              >
                <CalenderIcon className="h-5 transform scale-75" />
              </div>
            </div>
          </div>

          <div
            className={
              "grid grid-cols-7 w-full transform duration-300 " +
              (open_calender
                ? "scale-100 max-h-96 left-0 mt-2"
                : "scale-0 left-40 max-h-0")
            }
          >
            {DAYS.map((value, index) => {
              return (
                <div className="py-2" key={index}>
                  <div className="text-black text-sm uppercase tracking-wide md:font-semibold text-center text-gray-700">
                    {value}
                  </div>
                </div>
              );
            })}

            {getBlankDays(state).map((val, index) => {
              return (
                <div
                  key={index}
                  className="text-center px-2 py-2 text-gray-400"
                >
                  {val}
                </div>
              );
            })}
            {getDays(state, appointments).map((value, index) => {
              return (
                <div
                  className={
                    "px-1 py-1 relative flex justify-center items-center"
                  }
                  key={index}
                >
                  {/* <div className="absolute h-full w-full flex flex-col ">
                      <div className="h-1/6 w-full "></div>
                      <div className="h-4/6 w-full flex flex-row" >
                        <div className={"w-1/2 h-full  " + (value.start && value.end ? "" : (value.end ? value.edge : ""))}></div>
                        <div className={"w-1/2 h-full " + (value.start && value.end ? "" : (value.start ? value.edge : ""))}></div>
                      </div>
                      <div className="h-1/6 w-full"></div>
                    </div> */}
                  <div
                    onClick={() => {
                      onSelectCB(
                        new Date(
                          state.getFullYear(),
                          state.getMonth(),
                          index + 1
                        )
                      );
                    }}
                    className={
                      "inline-flex z-20 h-9 w-7 flex flex-col cursor-pointer text-center leading-none transition ease-in-out duration-100"
                    }
                  >
                    <span
                      className={
                        "rounded-full hover:bg-gray-400 w-full h-7 items-center justify-center flex " +
                        (date_selected &&
                        new Date(date_selected).toDateString() ===
                          new Date(
                            state.getFullYear(),
                            state.getMonth(),
                            index + 1
                          ).toDateString()
                          ? "bg-blue-500 text-white "
                          : "") +
                        dateStyler(isToday(index + 1, state))
                      }
                    >
                      {index + 1}
                    </span>
                    <div className="h-2 w-full flex flex-row gap-1 justify-center flex-wrap items-center">
                      {value.map((backgroundColor, index) => (
                        <div
                          key={index}
                          className={
                            "h-1 w-1 transform scale-125 rounded-full " +
                            backgroundColor
                          }
                        />
                      ))}
                    </div>
                  </div>
                  {/* <div className="overflow-y-auto h-5 mt-1">
                      <!-- <div
                                      className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center justify-center rounded-full text-sm w-6 h-6 bg-gray-700 text-white leading-none"
                                      x-show="events.filter(e => e.event_date === new Date(year, month, date).toDateString()).length"
                                      x-text="events.filter(e => e.event_date === new Date(year, month, date).toDateString()).length"></div> -->

                      {eventShow(value)}
                    </div> */}
                </div>
              );
            })}
            {[
              ...Array(
                (7 -
                  ((getBlankDays(state).length +
                    getDays(state, appointments).length) %
                    7)) %
                  7
              ),
            ].map((val, index) => {
              return <div key={index} className="text-center px-2 py-2 "></div>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
