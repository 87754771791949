import React from "react";
import { CartIcon, OpenEyeIcon } from "../../common/AppIcons";
import StatusBox from "../common/StatusBox";

export default function Materials({
  materials,
  set_material_popup,
  className,
}) {
  if (materials?.length > 0)
    return (
      <div
        className={
          "w-full px-2 mt-3 flex flex-col sm:flex-row items-start gap-4 " +
          className
        }
      >
        <div className="flex flex-row items-center gap-3">
          <CartIcon
            style={{ height: "18px", color: "#605BFF" }}
            className="sm:mt-2"
          />
          <span style={{ color: "#211C52" }} className="font-semibold">
            Materials
          </span>
        </div>
        <div className="w-full flex flex-col gap-3 flex-grow">
          {materials?.map((material, material_index) => (
            <div
              key={material_index}
              // style={{ backgroundColor: "#F8F8F8" }}
              className="bg-white w-full px-3 py-2 rounded flex flex-col sm:flex-row items-start sm:items-center flex-wrap border border-gray-300 gap-3 sm:grid sm:grid-cols-3"
            >
              <span className="text-gray-700 truncate">
                {material.name ?? ""}
              </span>
              <div className="flex flex-row items-center gap-4">
                <StatusBox status={material.method} />
                {material.method !== "IN_STOCK" && (
                  <StatusBox
                    status={material.isDelivered ? "DELIVERED" : "UNDELIVERED"}
                  />
                )}
              </div>
              <div
                className="w-full p-2 flex flex-row justify-end items-center gap-2 hover:opacity-70 cursor-pointer text-sm truncate"
                style={{ color: "#605BFF" }}
                onClick={() => set_material_popup(material)}
              >
                <OpenEyeIcon className="h-3" /> View Details
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  else return <div />;
}
