import React, { useState } from "react";
import ImgPopup from "../../common/ImgPopup";
import MediaUpload from "../../common/MediaUpload";
import { updateAppointmentStatus } from "../../../api/Api";
import { CrossIcon, LoadingIcon } from "../../common/AppIcons";
import Warning from "../../common/Warning";

export default function UpdateStatusToPickedUp({
  appointmentId,
  on_close,
  on_success,
  status = "PICKED_UP",
}) {
  const [data, set_data] = useState({ note: "", attachments: [] });
  const [view_files, set_view_files] = useState({ show: false, index: 0 });
  const [warning, set_warning] = useState();
  const [loading, set_loading] = useState(false);

  const convert_files = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const convert_files_and_upload = () => {
    set_loading(true);
    if (data.attachments.length === 0) update_apponitment_status([]);
    let file_arr = [];
    data.attachments.map((file) => {
      console.log(file);
      convert_files(file, (result) => {
        file_arr = [
          ...file_arr,
          { contentType: file.type, fileName: file.name, encodedFile: result },
        ];
        if (file_arr.length === data.attachments.length)
          update_apponitment_status(file_arr);
      });
    });
  };

  const update_apponitment_status = (converted_files) => {
    updateAppointmentStatus({
      appointmentId,
      status: status,
      notes: data.note,
      attachments: converted_files,
    })
      .then((res) => {
        console.log({ res });
        set_loading(false);
        on_success();
      })
      .catch((res) => {
        console.log({ res });
        set_warning(res.readableMessag ?? res.message ?? res.error ?? "Error ");
        set_loading(false);
      });
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full w-full">
        <LoadingIcon className="h-12 text-blue-600" />
      </div>
    );
  else
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col bg-white max-w-3xl w-full px-7 py-3 rounded shadow-md overflow-auto max-h-screen"
      >
        <Warning {...{ warning, set_warning }} />
        <div className="w-full flex justify-end">
          <span
            onClick={on_close}
            className="cursor-pointer hover:text-red-300 text-red-500"
          >
            <CrossIcon style={{ height: "23px" }} />
          </span>
        </div>
        <span className="text-gray-800 font-semibold">Picked Up Materials</span>
        <textarea
          style={{ backgroundColor: "#FAFAFB" }}
          className="mt-2 px-3 py-2 rounded w-full border-none bg-gray-100"
          value={data.note}
          onChange={(e) => {
            let val = e.target?.value;
            val &&
              set_data((data) => {
                return { ...data, note: val };
              });
          }}
          height={100}
          placeholder="Add notes here"
        />
        <span className="mt-4 text-gray-800 font-semibold">
          Add attachments
        </span>
        <div className="mt-2">
          <MediaUpload
            value={data.attachments}
            onChange={(val) =>
              set_data((data) => {
                return { ...data, attachments: val };
              })
            }
            open_popup={(index) =>
              set_view_files({ ...view_files, show: true, index: index })
            }
          />
        </div>

        <div className="py-6 sm:p-6 w-full flex flex-col-reverse sm:flex-row justify-end gap-5">
          <button
            className="rounded px-12 py-2 text-indigo-500 bg-indigo-100 focus:outline-none hover:opacity-80 font-semibold"
            onClick={on_close}
          >
            Cancel
          </button>
          <button
            className="rounded px-12 py-2 bg-indigo-500 text-indigo-100 hover:opacity-80"
            onClick={() => convert_files_and_upload()}
          >
            Update
          </button>
        </div>

        <ImgPopup
          view={view_files.show ?? false}
          setView={() => {
            set_view_files({ show: false });
          }}
          files={data.attachments.map((file) => URL.createObjectURL(file))}
          file_index={view_files.index}
          onChange={(val) => set_view_files({ ...view_files, index: val })}
        />
      </div>
    );
}
