export const timeDisplay = (display_time) => {
  if (!display_time) return "";
  const today = new Date();
  const endDate = new Date(display_time);
  const years = today.getFullYear() - endDate.getFullYear();
  const months = today.getMonth() - endDate.getMonth();
  const days = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60 * 24));
  const hours = parseInt((Math.abs(endDate - today) / (1000 * 60 * 60)) % 24);
  const minutes = parseInt(
    (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60
  );
  const seconds = parseInt(
    (Math.abs(endDate.getTime() - today.getTime()) / 1000) % 60
  );

  if (days > 7)
    return new Date(display_time).toLocaleDateString("en-us", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  else if (days > 0) return days + " day" + stringEnd(days);
  else if (hours > 0) return hours + " hour" + stringEnd(hours);
  else if (minutes > 0) return minutes + " min" + stringEnd(minutes);
  else return seconds + " second" + stringEnd(seconds);
};

const stringEnd = (val) => {
  if (Number(val) > 1) return "s Ago";
  else return " Ago";
};
