import React, { useState } from "react";
import { A } from "hookrouter";
import { resetPassword } from "../../api/Api";
import { Logo } from "../common/AppIcons";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    toast.info("Please Wait!");
    resetPassword(email)
      .then((response) => {
        toast.success(response.comment);
        setEmail("");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="h-full min-h-screen w-full flex flex-row items-center">
      <div className="h-full hidden lg:block">
        <img className="object-fill h-screen" alt="hero" src="/hero_pic.jpg" />
      </div>
      <div className="h-full flex-grow flex flex-col items-center justify-center text-left">
        <div className="flex flex-col items-start max-w-lg lg:mb-20">
          <Logo className="w-24" />
          <h1
            style={{ color: "#211C52", fontSize: "22px" }}
            className="mt-8 title-font font-semibold"
          >
            Reset Password
          </h1>

          <form
            noValidate
            onSubmit={(e) => e.preventDefault()}
            className="mt-4 flex w-full flex-col items-start gap-3"
          >
            <div className="w-full">
              <label
                htmlFor="hero-field"
                className="leading-7 text-sm opacity-70"
                style={{ color: "#030229", fontWeight: "550" }}
              >
                Enter Email
              </label>
              <input
                id="username"
                name="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full bg-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4 border-none"
              />
            </div>

            {errorMessage !== "" && (
              <div className="text-sm text-red-500">{errorMessage}</div>
            )}
            <button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              style={{ backgroundColor: "#605BFF" }}
              className="w-full text-white border-0 py-2 px-6 focus:outline-none hover:opacity-70 rounded text-sm"
            >
              Reset Password
            </button>
          </form>
          <div className="mt-3 flex flex-row items-center gap-2 text-sm">
            <span style={{ color: "#030229" }}>Remember Password?</span>
            <A href="/login" className="text-blue-600 hover:text-blue-800">
              Login
            </A>
          </div>
        </div>
      </div>
    </div>
  );
}
