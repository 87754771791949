import React from "react";
import { useRoutes, navigate } from "hookrouter";

import RecoverPassword from "../components/profile/RecoverPassword";
import ResetPassword from "../components/profile/ResetPassword";
import Login from "../components/profile/Login";
import FirebaseNotification from "../components/firebase_notification/FirebaseNotification";

export default function PublicRouter() {
  const routes = {
    "/": () => <Login />,

    "/forgot-password": () => <ResetPassword />,
    "/recover-password/:uuid/:email/endofurl": ({ uuid, email }) => (
      <RecoverPassword email={email} uuid={uuid} />
    ),
    "*": () => <Login />,
  };

  const routeResult = useRoutes(routes);
  // if(loggedIn)
  // return <AppRouter />
  // else if (routeResult)
  if (routeResult)
    return (
      <div>
        {" "}
        <FirebaseNotification />
        {routeResult}
      </div>
    );
  else navigate("/");
  return <div />;
}
