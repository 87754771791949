import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function SwipeableScreens({
    className,
    currentSlide,
    children,
    rerenderers = [],
}) {
    const [max_height, set_max_height] = useState();

    useEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    useEffect(() => {
        updateSize();
    }, [currentSlide, ...rerenderers]);

    const updateSize = () => {
        set_max_height(
            (document?.getElementById("slide_number_" + currentSlide)?.offsetHeight ?? 0) +
            40
        );
    };
    const swipingStyle = (cardNo, slide) => {
        const slideStyle = children.map((_, slide) => {
            return { transform: `translateX(${0 - slide * 100}%)` };
        });

        if (cardNo === slide) return slideStyle[slide - 1];
        else return { ...slideStyle[slide - 1], display: "hidden" };
    };
    if (typeof window !== "undefined")
        return (
            <div
                className={"flex flex-row overflow-hidden transform-all duration-400 " + className}
                style={{
                    maxHeight: max_height,
                }}
            >
                {children.map((child, renderIndex) => (
                    <div
                        key={renderIndex}
                        id={"slide_number_" + (renderIndex + 1)}
                        className="flex flex-col min-w-full transition duration-300 delay-100 overflow-x-hidden "
                        style={{
                            ...swipingStyle(renderIndex + 1, currentSlide),
                            maxWidth: "100%",
                        }}
                    >
                        {child}
                    </div>
                ))}
            </div>
        );
    else return null;
}
