import React from "react";

const status_variables = {
  REMEDIAL: {
    label: "Remedial",
    className: { color: "#CB0606", backgroundColor: "#FED3D3" },
  },

  IN_STOCK: {
    label: "In Stock",
    className: { color: "#303F99", backgroundColor: "#D0EAFF" },
  },
  PICK_UP: {
    label: "Pickup",
    className: { color: "#EA7405", backgroundColor: "#FFE7C7" },
  },
  JOB_SITE: {
    label: "Job Site",
    className: { color: "#605BFF", backgroundColor: "#E4E3FF" },
  },
  DELIVERED: {
    label: "Delivered",
    className: { color: "#17922F", backgroundColor: "#DCFFE3" },
  },
  UNDELIVERED: {
    label: "Undelivered",
    className: { color: "#D11A2A", backgroundColor: "#FFE3E3" },
  },

  // sr appointment status

  ASSIGNED: {
    label: "Assigned",
    className: { color: "#303F99", backgroundColor: "#D0EAFF" },
  },
  ACCEPTED: {
    label: "Accepted",
    className: { color: "#EA7405", backgroundColor: "#FFE7C7" },
  },
  ENROUTE: {
    label: "Enroute",
    className: { color: "#605BFF", backgroundColor: "#E4E3FF" },
  },
  STARTED: {
    label: "Started",
    className: { color: "#17922F", backgroundColor: "#DCFFE3" },
  },
  PICKED_UP: {
    label: "Picked Up",
    className: { color: "#17922F", backgroundColor: "#DCFFE3" },
  },
  FINISHED: {
    label: "Finished",
    className: { color: "#D11A2A", backgroundColor: "#FFE3E3" },
  },

  // appointmentType

  PICKUP_AND_SERVICE: {
    label: "Pickup & Service",
    className: { color: "#EA7405", backgroundColor: "#FFE7C7" },
  },
  PICKUP: {
    label: "Pickup",
    className: { color: "#605BFF", backgroundColor: "#E4E3FF" },
  },
  SERVICE: {
    label: "Service",
    className: { color: "#303F99", backgroundColor: "#D0EAFF" },
  },
};

export default function StatusBox({ status, className }) {
  if (!status) return null;
  else
    return (
      <span
        className={"text-xs rounded px-3 py-1 cursor-default " + className}
        style={{
          fontWeight: "500",
          ...(status_variables[status]?.className ?? {}),
        }}
      >
        {status_variables[status]?.label ?? status}
      </span>
    );
}
