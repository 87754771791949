import dispatcher from '../dispatcher';

export function updateActiveUser(text){
  dispatcher.dispatch({
    type: "updateActiveUser",
    text
  })
}

export function logoutUser(text){
  dispatcher.dispatch({
    type: "logoutUser",
    text
  })
}

