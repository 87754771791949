import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { LocationIcon2 } from "../common/AppIcons";

const defaultProps = {
    center: {
        lat: 34.0522,
        lng: -118.2437,
    },
    zoom: 11,
    options: {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 9
        },

    }
};

export default function Map({ data, themes, getAppointmentDetails, dayNoteElongate }) {

    const [hovering, set_hovering] = useState();
    const [center, set_center] = useState({
        lat: 34.0522,
        lng: -118.2437,
    })

    useEffect(() => {

        let coordinate = data?.filter((val, index) => index === dayNoteElongate)[0];

        if (coordinate && coordinate.geocodeLatitude) {
            set_center({
                lat: Number(coordinate.geocodeLatitude),
                lng: Number(coordinate.geocodeLongitude)
            })
        }
        // else set_center({
        //     lat: 34.0522,
        //     lng: -118.2437,
        // })
    }, [dayNoteElongate])

    return (
        <div
            className="flex flex-shrink-0 hidden lg:block"
            style={{ height: '100%', width: "100%" }}
        >
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyClTu9Ezl7dx86h571Bxht0aYPwEp_t3kg",
                }}
                defaultCenter={defaultProps.center}
                center={center}
                defaultZoom={defaultProps.zoom}
                options={defaultProps.options}
                yesIWantToUseGoogleMapApiInternals

            >
                {data?.map((geo, index) => {
                    return (
                        <div onMouseEnter={() => set_hovering(index)}
                            onMouseLeave={() => set_hovering()}
                            className='cursor-pointer'
                            onClick={() => getAppointmentDetails(geo.appointmentId)}
                            key={index}
                            lat={geo.geocodeLatitude}
                            lng={geo.geocodeLongitude} >
                            <LocationIcon2 className={"text-xl h-10 w-10 relative right-4 bottom-7 transform-all duration-100  "
                                + (dayNoteElongate === index || index === hovering ? 'h-12 w-12 right-5 bottom-9 ' : ' ')
                                + themes[index % 5].text} />
                        </div>
                    );
                }) || React.null}
            </GoogleMapReact>
        </div>
    );
}
