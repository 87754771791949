import React, { useState, useEffect } from "react";

import {
  getNotifications,
  getUserInfo,
  notificationMarkAsRead,
} from "../../api/Api";
import Popup from "../common/Popup";
import TopButtons from "../dashboard/TopButtons";
import UserProfile from "../user/UserProfile";
import ActiveUserStore from "../../store/ActiveUserStore";
import { LoadingIcon, Logo } from "../common/AppIcons";
import { timeDisplay } from "../../utils/timeDisplay";
import { months } from "../../utils/DateUtils";
import WebSocket from "../../utils/WebSocket";

export default function Notications({}) {
  const [associateData, setAssociateData] = useState({});
  const [popup, setPopup] = useState(false);
  const [notifications, set_notifications] = useState({ notifications: [] });
  const [loading_notification, set_loading_notification] = useState(true);
  const [loading_more, set_loading_more] = useState(false);
  const [current_page, set_current_page] = useState(0);
  const [no_new_notification, set_no_new_notification] = useState(false);

  useEffect(() => {
    getUserInfo()
      .then((data) => {
        setAssociateData(data);
        console.log("user Data Received from Server", data);
      })
      .catch((data) => {
        console.log("error", data);
      });
  }, []);

  useEffect(() => {
    get_notification();
  }, [current_page]);

  const get_notification = (page_number) => {
    getNotifications({ size: 25, page: page_number ?? current_page })
      .then((res) => {
        let notificationIds = [];
        set_notifications((notifications) => {
          return {
            ...res,
            notifications: [
              ...notifications?.notifications,
              ...(res.notifications ?? []),
            ].filter((val) => {
              if (notificationIds.includes(val.notificationId)) return false;
              else {
                notificationIds = [...notificationIds, val.notificationId];
                return true;
              }
            }),
          };
        });

        console.log("notifications", res);
        set_loading_notification(false);
        set_loading_more(false);
        if (res?.notifications?.length === 0) set_no_new_notification(true);
      })
      .catch((res) => {
        console.log(res);
        set_loading_notification(false);
        set_loading_more(false);
        set_no_new_notification(true);
      });
  };

  const onClick_notification = (notificationId, link_url) => {
    set_loading_notification(true);
    notificationMarkAsRead({ notificationId })
      .then((res) => {
        set_loading_notification(false);
        console.log(res);
        link_open(link_url);
      })
      .catch((res) => {
        set_loading_notification(false);
        console.log(res);
        link_open(link_url);
      });
  };

  const link_open = (url) => {
    if (!url) return;
    if (url === "/messages") {
      window.location.href =
        window.innerWidth < 1024 ? url : "/dashboard/messages";
    }
  };

  return (
    <div
      className="h-screen w-full px-8 pt-10 pb-16 overflow-auto"
      onScroll={(e) => {
        if (
          !no_new_notification &&
          !loading_more &&
          notifications?.notifications?.length !== 0 &&
          e.target.scrollTop === e.target.scrollTopMax
        ) {
          set_loading_more(true);
          set_current_page((current_page) => current_page + 1);
        }
      }}
    >
      <WebSocket
        url={
          associateData?.associateId
            ? "/notifications/associate_webapp_notifications/" +
              associateData?.associateId
            : null
        }
        onMessage={(msg) => {
          console.log(
            "refreshed from /notifications/associate_webapp_notifications/" +
              associateData?.associateId,
            msg
          );
          get_notification(0);
        }}
        reset_array={[associateData?.associateId]}
      />
      <div className="max-w-7xl mb-6 mx-auto w-full flex flex-row items-center justify-between">
        <a href="/">
          <Logo className="w-20" />
        </a>

        <TopButtons
          userData={associateData}
          openUserProfile={() => setPopup(true)}
          logOut={(_) => {
            console.log("Logging Out");
            ActiveUserStore.logoutUser();
          }}
          hide_open_notification={true}
          className="flex flex-col items-end"
        />
      </div>

      <div className="mt-6 max-w-5xl mx-auto w-full flex flex-col items-center">
        <div className="mb-8 sm:mb-12 w-full flex flex-row flex-wrap items-center justify-between">
          <h1 className="text-indigo-800 text-3xl font-semibold">
            Notifications
          </h1>
          <button className="px-4 py-2 focus:outline-none font-semibold text-sm border border-indigo-800 rounded-full text-indigo-800 hover:text-white hover:bg-indigo-800 duration-300">
            Mark all as read
          </button>
        </div>

        {loading_notification ? (
          <LoadingIcon className="h-5 my-12 text-blue-400" />
        ) : (
          notifications?.notifications?.length > 0 && (
            <div className="w-full flex flex-col gap-8">
              {divide_based_on_time(notifications?.notifications)?.map(
                (ele, ele_index, arr) => (
                  <div
                    key={ele_index}
                    className="flex flex-col items-start gap-4"
                  >
                    <h3 className="text-indigo-800 text-xl font-semibold">
                      {ele.title}
                    </h3>
                    <div className="w-full flex flex-col divide-y">
                      {ele?.notifications?.map(
                        (notification, notification_index) => (
                          <a
                            id={
                              arr.length === ele_index &&
                              ele?.notifications.length === notification_index
                                ? "last_notification"
                                : ""
                            }
                            key={notification_index}
                            href={notification.link}
                            onClick={(e) => {
                              e.preventDefault();
                              onClick_notification(
                                notification.notificationId,
                                notification.link
                              );
                            }}
                            className={
                              "border-t w-full text-sm flex flex-row gap-2 items-start justify-between py-3 " +
                              (notification.link ? "hover:bg-gray-100 " : "") +
                              (notification.isRead ? "opacity-70 " : "")
                            }
                          >
                            <div className="flex flex-col gap-1 items-start">
                              <span
                                style={{ fontSize: "16px", fontWeight: "600" }}
                                className="text-gray-800"
                              >
                                {notification.title}
                              </span>

                              <span
                                style={{ fontSize: "15px", fontWeight: "400" }}
                                className="text-gray-700"
                              >
                                {notification.content}
                              </span>
                            </div>

                            <span className="text-gray-700 text-xs">
                              {timeDisplay(notification.time)}
                            </span>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          )
        )}

        {no_new_notification && (
          <span className="w-full text-center text-gray-500 text-sm px-5 mt-7">
            No New Notifications
          </span>
        )}

        {loading_more && (
          <div
            className={
              "h-10 w-10 p-2 bg-white text-blue-300 rounded-full flex items-center justify-center"
            }
          >
            <LoadingIcon />
          </div>
        )}
      </div>
      <Popup
        view={popup}
        onClose={() => {
          setPopup(!popup);
        }}
      >
        <UserProfile
          onClose={() => {
            setPopup(!popup);
          }}
        />
      </Popup>
    </div>
  );
}

const divide_based_on_time = (base_notifications) => {
  let notifications_divided = [];

  let notifications = base_notifications?.sort(
    (a, b) => new Date(b.time) - new Date(a.time)
  );

  let today = new Date();

  let today_notifications = notifications.filter(
    (val) => new Date(val.time).getDate() === today.getDate()
  );

  if (today_notifications?.length > 0) {
    notifications_divided = [
      ...notifications_divided,
      { title: "Today", notifications: today_notifications },
    ];

    notifications = notifications.filter(
      (val) => new Date(val.time).getDate() !== today
    );
  }

  let this_week = notifications.filter(
    (val) =>
      new Date(val.time).getFullYear() === today.getFullYear() &&
      weekNumber(new Date(val.time)) === weekNumber(today)
  );

  if (this_week?.length > 0) {
    notifications_divided = [
      ...notifications_divided,
      { title: "This week", notifications: this_week },
    ];

    notifications = notifications.filter(
      (val) =>
        new Date(val.time).getFullYear() !== today.getFullYear() ||
        weekNumber(new Date(val.time)) !== weekNumber(today)
    );
  }

  let this_month = notifications.filter(
    (val) =>
      new Date(val.time).getFullYear() === today.getFullYear() &&
      new Date(val.time).getMonth() === today.getMonth()
  );

  if (this_month?.length > 0) {
    notifications_divided = [
      ...notifications_divided,
      { title: "This Month", notifications: this_month },
    ];

    notifications = notifications.filter(
      (val) =>
        new Date(val.time).getFullYear() !== today.getFullYear() ||
        new Date(val.time).getMonth() !== today.getMonth()
    );
  }

  let current_month = new Date(today.getFullYear(), today.getMonth(), 1);

  while (notifications?.length > 0) {
    let this_month_notifications = notifications.filter(
      (val) =>
        new Date(val.time).getFullYear() === current_month.getFullYear() &&
        new Date(val.time).getMonth() === current_month.getMonth()
    );

    if (this_month_notifications?.length > 0) {
      notifications_divided = [
        ...notifications_divided,
        {
          title:
            months[current_month?.getMonth()] +
            " " +
            current_month?.getFullYear(),
          notifications: this_month_notifications,
        },
      ];

      notifications = notifications.filter(
        (val) =>
          new Date(val.time).getFullYear() !== current_month.getFullYear() ||
          new Date(val.time).getMonth() !== current_month.getMonth()
      );
    }

    current_month.setMonth(current_month.getMonth() - 1);
  }

  return notifications_divided;
};

const weekNumber = (date = new Date()) => {
  var firstJanuary = new Date(date.getFullYear(), 0, 1);
  var dayNr = Math.ceil((date - firstJanuary) / (24 * 60 * 60 * 1000));
  var weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);
  return weekNr;
};
