import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

import Input1 from "../common/Input1";
import {
  getUserInfo,
  updateUserInfo,
  fileUpload,
  getAddressAutoComplete,
} from "../../api/Api";
import { CrossCicleIcon, UploadIcon } from "../common/AppIcons";
import SelectHeadings from "../common/SelectHeadings";
import SwipeableScreens from "../common/SwipableScreens";
import { states } from "../../assets/constants";
import { toast } from "react-toastify";

const uploadEncodedFile = (callback, file) => {
  return function (upload) {
    callback({
      fileName: file.name,
      contentType: file.type,
      encodedFile: upload.target.result,
    });
  };
};

const addressDisplay = (address) => {
  let real_address = address.Address;

  if (!real_address) return null;
  return (
    (real_address.Address1 ? real_address.Address1 + ", " : "") +
    (real_address.Locality || real_address.SubAdministrativeArea
      ? (real_address.Locality || real_address.SubAdministrativeArea) + ",  "
      : "") +
    (real_address.AdministrativeArea ?? "") +
    (real_address.PostalCodePrimary
      ? " " + real_address.PostalCodePrimary
      : "") +
    (real_address.SubBuilding && real_address.SubBuilding !== "" ? " Apt" : "")
  );
};

const insurence_options = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No",
  },
];

const notify_options = [
  {
    label: "Text",
    value: "TEXT",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "Text and Email",
    value: "TEXT_AND_EMAIL",
  },
];

export default function UserProfile({ onClose }) {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [address_hints, set_address_hints] = useState([]);
  const [streetAddress2_hints, set_streetAddress2_hints] = useState([]);
  const [input_focus, set_input_focus] = useState({
    streetAddress1: false,
    streetAddress2: false,
  });
  const [last_address_keyword, set_last_address_keyword] = useState("");
  const [streetAddress1_debounce] = useDebounce(
    userData?.address?.streetAddress1,
    700
  );
  const [address_hint_loading, set_address_hint_loading] = useState(false);

  const uploadCB = (data) =>
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        setUserData((userData) => {
          return { ...userData, profilePic: res.value };
        });
        setLoading(false);
      })
      .catch((failure) => {
        console.log("Failure", failure);
        setUserData((userData) => {
          return { ...userData, profilePic: undefined };
        });
        setLoading(false);
      });

  const handleSelectFile = (event) => {
    setLoading(true);
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadCB, file);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setLoading(true);
    getUserInfo()
      .then((data) => {
        console.log("operater data", data);
        setUserData(data);
        setLoading(false);
      })
      .catch((data) => {
        console.log("error", data);
        toast.error(
          data?.readableMessage ?? data?.message ?? "Error getting user data",
          {
            position: "bottom-center",
          }
        );
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      userData?.address?.streetAddress1?.split(" ")?.length > 0 &&
      userData?.address?.streetAddress1?.charAt(
        userData?.address?.streetAddress1.length - 1
      ) === " "
    ) {
      get_address_hints(userData?.address?.streetAddress1);
    }
  }, [userData?.address?.streetAddress1]);

  useEffect(() => {
    if (
      input_focus?.streetAddress1 &&
      userData?.address?.streetAddress1 &&
      streetAddress1_debounce === userData?.address?.streetAddress1
    ) {
      get_address_hints(userData?.address?.streetAddress1);
    }
  }, [
    input_focus?.streetAddress1,
    userData?.address?.streetAddress1,
    streetAddress1_debounce,
  ]);

  const get_address_hints = (address) => {
    if (!address) return;

    if (address?.trim() !== "" && address?.trim() !== last_address_keyword) {
      set_last_address_keyword(address.trim());
      set_address_hint_loading(true);

      getAddressAutoComplete({
        address,
      })
        .then((res) => {
          console.log("address hints:", res);
          set_address_hints(res);
          set_address_hint_loading(false);
        })
        .catch((res) => {
          console.log("address hints error:" + res);
          set_address_hint_loading(false);
        });
    }
  };

  const onSubmit = () => {
    setLoading(true);
    console.log({ userData });
    updateUserInfo(userData)
      .then((res) => {
        setLoading(false);
        console.log(res);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        toast.error(
          res.readableMessage || res.message || "Error posting user data",
          {
            position: "bottom-center",
          }
        );
      });
  };

  const fields = () => {
    return [
      {
        heading: "My details",
        fields: [
          {
            label: "First Name",
            value: userData.firstName,
            name: "firstName",
          },
          {
            label: "Last Name",
            value: userData.lastName,
            name: "lastName",
          },
          {
            label: "Home City",
            value: userData.homeCity,
            name: "homeCity",
          },
          {
            label: "Phone",
            value: userData.phone,
            name: "phone",
            type: "phone",
          },
          {
            label: "Alias",
            value: userData.alias,
            name: "alias",
          },
          {
            label: "Date of Birth",
            value: userData.dateOfBirth?.split("T")[0],
            name: "dateOfBirth",
            type: "date",
          },
          {
            label: "Notify by",
            value: userData.notifyBy,
            name: "notifyBy",
            type: "drop_down",
            options: notify_options,
          },

          ...(userData.type === "THIRD_PARTY_VENDOR"
            ? [
                {
                  label: "Company Name",
                  value: userData.companyName,
                  name: "companyName",
                },
              ]
            : [
                {
                  label: "Driverse License",
                  value: userData.driversLicense,
                  name: "driversLicense",
                },
                {
                  label: "Driver License Expiry",
                  value: userData.driversLicenseExpiry?.split("T")[0],
                  name: "driversLicenseExpiry",
                  type: "date",
                },
              ]),
        ],
      },
      {
        heading:
          userData.type === "THIRD_PARTY_VENDOR" ? "Metadata" : "Work Schedule",
        fields:
          userData.type === "THIRD_PARTY_VENDOR"
            ? [
                {
                  label: "Contractor License",
                  value: userData.metadata?.contractorLicense,
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          contractorLicense: val === "true" ? true : false,
                        },
                      };
                    });
                  },
                  type: "drop_down",
                  options: insurence_options,
                },
                {
                  label: "Contractor License Number",
                  value:
                    userData.metadata?.contractorLicenseNumber?.split("T")[0],
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          contractorLicenseNumber: val,
                        },
                      };
                    });
                  },
                  className:
                    userData.metadata?.contractorLicense === true ||
                    userData.metadata?.contractorLicense === "true"
                      ? ""
                      : "hidden",
                },
                {
                  label: "Contractor License Expiry",
                  value:
                    userData.metadata?.contractorLicenseExpiry?.split("T")[0],
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          contractorLicenseExpiry: val,
                        },
                      };
                    });
                  },
                  type: "date",
                  className:
                    userData.metadata?.contractorLicense === true ||
                    userData.metadata?.contractorLicense === "true"
                      ? ""
                      : "hidden",
                },
                {
                  label: "Liability Insurance",
                  value: userData.metadata?.liabilityInsurance,
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          liabilityInsurance: val === "true" ? true : false,
                        },
                      };
                    });
                  },
                  type: "drop_down",
                  options: insurence_options,
                },
                {
                  label: "Liability Insurance Expiry",
                  value:
                    userData.metadata?.liabilityInsuranceExpiry?.split("T")[0],
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          liabilityInsuranceExpiry: val,
                        },
                      };
                    });
                  },
                  type: "date",
                  className:
                    userData.metadata?.liabilityInsurance === true ||
                    userData.metadata?.liabilityInsurance === "true"
                      ? ""
                      : "hidden",
                },
                {
                  label: "Workers Compensation Insurance",
                  value: userData.metadata?.workersCompensationInsurance,
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          workersCompensationInsurance:
                            val === "true" ? true : false,
                        },
                      };
                    });
                  },
                  type: "drop_down",
                  options: insurence_options,
                },
                {
                  label: "Workers Compensation Insurance Expiry",
                  value:
                    userData.metadata?.workersCompensationInsuranceExpiry?.split(
                      "T"
                    )[0],
                  onChange: (val) => {
                    setUserData((userData) => {
                      return {
                        ...userData,
                        metadata: {
                          ...userData.metadata,
                          workersCompensationInsuranceExpiry: val,
                        },
                      };
                    });
                  },
                  type: "date",
                  className:
                    userData.metadata?.workersCompensationInsurance === true ||
                    userData.metadata?.workersCompensationInsurance === "true"
                      ? ""
                      : "hidden",
                },
              ]
            : [
                {
                  label: "Work Day Start",
                  value: userData?.workdayStart,
                  name: "workdayStart",
                  type: "time",
                },
                {
                  label: "Work Day End",
                  value: userData?.workdayEnd,
                  name: "workdayEnd",
                  type: "time",
                },
                {
                  label: "Saturday Start",
                  value: userData?.saturdayStart,
                  name: "saturdayStart",
                  type: "time",
                },
                {
                  label: "Saturday End",
                  value: userData?.saturdayEnd,
                  name: "saturdayEnd",
                  type: "time",
                },
                {
                  label: "Sunday Start",
                  value: userData?.sundayStart,
                  name: "sundayStart",
                  type: "time",
                },
                {
                  label: "Sunday End",
                  value: userData?.sundayEnd,
                  name: "sundayEnd",
                  type: "time",
                },
              ],
      },
      {
        heading: "Address",
        fields: [
          {
            label: "Street Address 1",
            value: userData.address.streetAddress1,
            optionsLoading: address_hint_loading,
            onMouseEnter: () => {
              set_input_focus((input_focus) => {
                return { ...input_focus, streetAddress1: true };
              });
            },
            onMouseLeave: () => {
              set_input_focus((input_focus) => {
                return { ...input_focus, streetAddress1: false };
              });
            },
            onChange: (val) => {
              set_input_focus((input_focus) => {
                return { ...input_focus, streetAddress1: true };
              });
              setUserData((userData) => {
                return {
                  ...userData,
                  address: { ...userData.address, streetAddress1: val },
                };
              });
            },
            options: address_hints?.map((address) => {
              return {
                label: addressDisplay(address),
                value: address,
              };
            }),
            optionOnClick: (address) => {
              let real_address = address?.Address;
              console.log(real_address);
              setUserData((data) => {
                return {
                  ...data,
                  address: {
                    melissaBaseMak: real_address?.BaseMAK ?? real_address?.MAK,
                    units: real_address?.BaseMAK
                      ? {
                          units: real_address?.SubBuilding.split(",").filter(
                            (val) => val !== ""
                          ),
                          maks: real_address?.MAK.split(",").filter(
                            (val) => val !== ""
                          ),
                        }
                      : null,
                    streetAddress1: real_address?.Address1,
                    city:
                      real_address?.Locality ||
                      real_address?.SubAdministrativeArea,
                    state: real_address?.AdministrativeArea,
                    zip: real_address?.PostalCodePrimary,
                    geocodeLatitude: real_address?.Latitude,
                    geocodeLongitude: real_address?.Longitude,
                  },
                };
              });

              set_streetAddress2_hints(() => {
                return real_address?.SubBuilding?.split(",").filter(
                  (val) => val !== ""
                );
              });
              set_address_hints();
              set_last_address_keyword();

              setTimeout(() => {
                document.getElementById("Street Address 2")?.focus();
              }, 300);
            },
          },
          {
            id: "Street Address 2",
            label: "inputStreet Address 2",
            value: userData.address.streetAddress2,
            onChange: (val) => {
              set_input_focus((input_focus) => {
                return { ...input_focus, streetAddress2: true };
              });
              setUserData((userData) => {
                return {
                  ...userData,
                  address: { ...userData.address, streetAddress2: val },
                };
              });
            },
            onMouseEnter: () => {
              set_input_focus((input_focus) => {
                return { ...input_focus, streetAddress2: true };
              });
            },
            onMouseLeave: () => {
              set_input_focus((input_focus) => {
                return { ...input_focus, streetAddress2: false };
              });
            },
            options: streetAddress2_hints?.map((address) => {
              return {
                label: address,
                value: address,
              };
            }),
          },
          {
            label: "City",
            value: userData.address.city,
            onChange: (val) => {
              setUserData((userData) => {
                return {
                  ...userData,
                  address: { ...userData.address, city: val },
                };
              });
            },
          },
          {
            label: "State",
            value: userData.address.state,
            onChange: (val) => {
              setUserData((userData) => {
                return {
                  ...userData,
                  address: { ...userData.address, state: val },
                };
              });
            },
            type: "drop_down",
            options: states,
          },
          {
            label: "Zip Code",
            value: userData.address.zip,
            onChange: (val) => {
              setUserData((userData) => {
                return {
                  ...userData,
                  address: { ...userData.address, zip: val },
                };
              });
            },
          },
        ],
      },
    ];
  };

  return (
    <div
      style={{ minWidth: "350px" }}
      className="flex flex-col p-3 md:px-10 md:py-8"
    >
      {loading && (
        <div className="fixed flex items-center justify-center z-40 inset-0 bg-opacity-80 bg-white">
          <div
            style={{ borderTopColor: "transparent" }}
            className="border-solid animate-spin rounded-full border-blue-400 border-4 h-12 w-12"
          ></div>
        </div>
      )}
      <div className="flex flex-row justify-between items-center">
        <span className="text-xl font-semibold">Profile Settings</span>
        <div
          className="cursor-pointer text-red-600 hover:text-red-300"
          onClick={onClose}
        >
          <CrossCicleIcon className="h-7" />
        </div>
      </div>
      {userData ? (
        <div>
          <SelectHeadings
            headings={fields().map((val, index) => {
              return {
                label: <span className="flex items-center">{val.heading}</span>,
                value: index,
              };
            })}
            selected={selectedTab}
            onChange={(val) => {
              setSelectedTab(val);
            }}
            width_array={[110, 140, 100]}
            className="mt-3 md:mt-5 relative top-1 flex flex-grow w-full"
            gap={5}
          />
          <hr />

          <SwipeableScreens
            rerenderers={[
              userData?.metadata?.liabilityInsurance,
              userData?.metadata?.workersCompensationInsurance,
              userData?.metadata?.contractorLicense,
            ]}
            className="items-start"
            currentSlide={selectedTab + 1}
          >
            {fields().map((field_set, field_set_index) => (
              <div
                key={field_set_index}
                className="w-full grid md:grid-cols-2 gap-5 mt-6 overflow-hidden"
              >
                {field_set.heading === "My details" && (
                  <div className="md:col-span-2 flex justify-start">
                    <div className="relative rounded-full overflow-hidden block">
                      <img
                        className="relative rounded-full w-32 h-32 object-cover"
                        src={userData?.profilePic}
                        alt=""
                      />
                      <label
                        htmlFor="user-photo"
                        className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 duration-100"
                      >
                        <UploadIcon className="h-9" />
                        <span className="sr-only"> user photo</span>
                        <input
                          type="file"
                          id="user-photo"
                          name="user-photo"
                          onChange={handleSelectFile}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                        />
                      </label>
                    </div>
                  </div>
                )}

                {field_set.fields?.map((field, field_index) => (
                  <Input1
                    key={field_index}
                    {...field}
                    className={"lg:px-3 my-1 " + field.className}
                    options={field.options}
                    onChange={(val) => {
                      field.onChange
                        ? field.onChange(
                            field.type && field.type === "date"
                              ? val + "T00:00:00.000+00:00"
                              : val
                          )
                        : setUserData(
                            field.type && field.type === "date"
                              ? {
                                  ...userData,
                                  [field.name]: val + "T00:00:00.000+00:00",
                                }
                              : { ...userData, [field.name]: val }
                          );
                    }}
                  />
                ))}
              </div>
            ))}
          </SwipeableScreens>
          <div className="mt-5 w-full flex flex-row-reverse items-center gap-5">
            <button
              className="px-10 py-2 text-sm rounded shadow-md focus:outline-none text-white bg-indigo-600 hover:opacity-70"
              onClick={onSubmit}
            >
              Update
            </button>
            <button
              className="px-10 py-2 text-sm rounded focus:outline-none font-semibold text-indigo-600 bg-indigo-100 hover:opacity-70"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <span className="my-20 w-full text-gray-600 text-center">
          User data not found
        </span>
      )}
    </div>
  );
}
