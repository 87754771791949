
export const ToastMessage = ({ closeToast, toastProps, payload }) => (
    <div {...toastProps} onClick={() => notification_onClick(payload?.notification?.title)} className="flex flex-col">
        <div className="flex flex-fow justify-between items-center w-full font-semibold text-sm">
            {payload?.notification?.title ?? ""}
        </div>
        <span className="mt-1 text-sm">{payload?.notification?.body}</span>
    </div>
);


const notification_onClick = (title) => {
    if (title === 'New Message') {
        window.location.href = window.innerWidth < 1024 ? '/messages' : '/dashboard/messages'
    }
    else window.location.href = '/notifications'
}