import React, { useEffect, useState } from "react";
import { useRoutes } from "hookrouter";

import Dashboard from "../components/dashboard/Dashboard";
import ChatComponent from "../components/chat/ChatComponent";
import Notifications from "../components/notification/Notifications";
import FirebaseNotification from "../components/firebase_notification/FirebaseNotification";

export default function AppRouter() {
  const routes = {
    "/messages": () => <ChatComponent />,
    "/notifications": () => <Notifications />,
    "/dashboard/:screen": ({ screen }) => <Dashboard screen={screen} />,
    "/appointment/:id": ({ id }) => <Dashboard appointmentId={id} />,
    "/appointment/:id/:parameter": ({ id, parameter }) => (
      <Dashboard appointmentId={id} parameter={parameter} />
    ),
    "/": () => <Dashboard />,
    "/*": () => <Dashboard />,
  };

  const routeResult = useRoutes(routes);

  if (routeResult)
    return (
      <html>
        <head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Manrope:wght@600&family=Nunito:wght@400;500&family=PT+Serif:wght@700&display=swap"
            rel="stylesheet"
          />
        </head>
        <body>
          <div style={{ fontFamily: "Nunito" }} className="h-full w-full">
            <FirebaseNotification />
            {routeResult}
          </div>
        </body>
      </html>
    );
}
