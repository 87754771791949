import { Transition } from "@tailwindui/react";
import React, { useState, useEffect } from "react";

import {
  LeftArrowIcon,
  WorkerIcon,
  ClockIcon2,
  WarningIcon,
  PaperClipIcon,
  LocationIcon,
  ImageIcon2,
  NumberPadIcon,
  Logo,
  NoteIcon,
  PlayIcon,
} from "../common/AppIcons";
import TaskTicker from "../common/TaskTicker";
import ImgPopup from "../common/ImgPopup";
import UpdateStatusToEnroute from "../dashboard/change_appointment_status_popup/UpdateStatusToEnroute";
import UpdateStatusToFinished from "../dashboard/change_appointment_status_popup/UpdateStatusToFinished";
import UpdateStatusToStarted from "../dashboard/change_appointment_status_popup/UpdateStatusToStarted";
import { getSRDetails } from "../../api/Api";
import TicketIdWithPrefix from "../common/TicketIdWithPrefix";
import UpdateStatusToAccepted from "../dashboard/change_appointment_status_popup/UpdateStatusToAccepted";
import MaterialPopup from "./components/MaterialPopup";
import Materials from "./components/Materials";
import Contact from "./components/Contact";
import StatusBox from "./common/StatusBox";
import Pickup from "./components/Pickup";
import UpdateStatusToPickedUp from "../dashboard/change_appointment_status_popup/UpdateStatusToPickedUp";
import UpdateStatusToDelivered from "../dashboard/change_appointment_status_popup/UpdateStatusToDelivered";
import { findAppointmentStatus } from "../dashboard/utility/findAppointmentStatus";
import { convertTimezone } from "../../utils/convertTimezone";
import { addressFormat } from "../../utils/AddressFormat";
import { findFileTypeFromURL } from "../../utils/findFileTypeFromURL";

const date_format = (date) => {
  var today = convertTimezone(date);
  if (!date) return undefined;

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return mm + "-" + dd + "-" + yyyy;
};

export default function SRDetail({
  appointmentId,
  parameter,
  loading,
  set_loading,
  go_back,
  get_appointments,
}) {
  const [view_files, set_view_files] = useState({
    files: [],
    show: false,
    index: 0,
  });
  const [update_status_popup, set_update_status_popup] = useState();
  const [serviceRequest, set_serviceRequest] = useState();
  const [material_popup, set_material_popup] = useState();

  const base_tasks = [
    {
      label: "Assigned",
      value: "ASSIGNED",
    },
    {
      label: "Accepted",
      value: "ACCEPTED",
    },
    {
      label: "Enroute",
      value: "ENROUTE",
    },
    ...(serviceRequest?.appointments[0]?.appointmentType === "PICKUP"
      ? [
          {
            label: "Picked Up",
            value: "PICKED_UP",
          },
          {
            label: "Delivered",
            value: "DELIVERED",
          },
        ]
      : serviceRequest?.appointments[0]?.appointmentType ===
        "PICKUP_AND_SERVICE"
      ? [
          {
            label: "Picked Up",
            value: "PICKED_UP",
          },
          {
            label: "Started",
            value: "STARTED",
          },
          {
            label: "Finished",
            value: "FINISHED",
          },
        ]
      : [
          {
            label: "Started",
            value: "STARTED",
          },
          {
            label: "Finished",
            value: "FINISHED",
          },
        ]),
  ];

  useEffect(() => {
    if (
      serviceRequest &&
      parameter &&
      (parameter === "accept" || parameter === "reject")
    )
      if (appointmentId !== serviceRequest?.appointments[0]?.appointmentId)
        window.location.href = "/appointment/" + appointmentId;
      else
        set_update_status_popup(
          parameter === "accept" ? "ACCEPTED" : "REJECTED"
        );
  }, [serviceRequest, parameter]);

  useEffect(() => {
    getAppointmentDetails();
  }, [appointmentId]);

  const getAppointmentDetails = () => {
    set_loading(true);
    getSRDetails(appointmentId)
      .then((res) => {
        console.log("appointment details", res);
        set_serviceRequest(res);
        document
          ?.getElementById("main_section")
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        set_loading(false);
      })
      .catch((res) => {
        set_loading(false);
        console.log(res);
      });
  };

  const viewFiles = () => {
    let temp_files = [];

    temp_files = [
      ...temp_files,
      ...(serviceRequest?.appointments
        ? serviceRequest?.appointments.map((val) => {
            let temp_arr = [];
            if (val.beforeUrls && val.beforeUrls.length > 0)
              temp_arr = [...temp_arr, ...val.beforeUrls];
            if (val.afterUrls && val.afterUrls.length > 0)
              temp_arr = [...temp_arr, ...val.afterUrls];
            return temp_arr;
          })
        : []),
    ];

    let ans = [];

    for (let i = 0; i < temp_files.length; i++) {
      ans = [...ans, ...temp_files[i]];
    }

    let temp_ans = [];

    if (serviceRequest?.mediaUrls && serviceRequest.mediaUrls?.length > 0)
      temp_ans = [...temp_ans, ...serviceRequest?.mediaUrls];

    ans = [...temp_ans, ...ans];

    return ans;
  };

  const sr_ticket_media = () => {
    if (!serviceRequest) return [];

    let arr = [
      ...(serviceRequest?.ticketMediaUrls ?? []),
      ...(serviceRequest?.ticketMedia ?? []),
    ];
    if (serviceRequest?.blacklistedTicketMedia)
      arr = arr.filter((val) => {
        return !serviceRequest?.blacklistedTicketMedia?.includes(val);
      });
    return arr;
  };

  if (!serviceRequest)
    return (
      <div className="w-full h-full flex justify-center p-12 font-semibold bg-white text-gray-600">
        {!loading && " Unable to fetch Appointment details"}
      </div>
    );
  return (
    <div
      style={{ backgroundColor: "#FAFAFB" }}
      className={
        "flex flex-col items-start mx-auto flex-shrink-0 pt-4 lg:overflow-auto h-full w-full sm:px-16 sm:max-w-5xl " +
        (go_back ? "" : "max-w-6xl")
      }
    >
      <div
        style={{ backgroundColor: "#FAFAFB" }}
        className="w-full flex flex-col items-start"
      >
        <Logo className="w-20 mt-10 ml-5 sm:hidden" />
        <a
          href={go_back ? undefined : "/"}
          onClick={go_back}
          className="mt-7 ml-5 text-gray-800 hover:opacity-80 flex flex-row gap-2 cursor-pointer items-center"
        >
          <LeftArrowIcon className="w-4 relative sm:top-px" />
          <h3 className="text-gray-700 text-sm font-semibold">Go back</h3>
        </a>

        <div className="w-full mt-6 sm:mt-10">
          <div
            className={
              "pl-5 mb-2 flex flex-col-reverse sm:flex-row gap-4 w-full sm:justify-between"
            }
          >
            <div className="w-full flex justify-between flex-row flex-wrap gap-4 items-center">
              <span
                style={{ color: "#605BFF" }}
                className="text-xl font-semibold break-words whitespace-pre-line"
              >
                {TicketIdWithPrefix(serviceRequest)}
              </span>
              <div className="flex flex-row items-center gap-3 flex-wrap">
                {serviceRequest?.appointments[0]?.remedial && (
                  <StatusBox className="text-sm" status={"REMEDIAL"} />
                )}
                <StatusBox
                  className="text-sm"
                  status={findAppointmentStatus({
                    type: serviceRequest?.appointments[0]?.appointmentType,
                    status: serviceRequest?.appointments[0]?.status,
                  })}
                />
                <StatusBox
                  className="text-sm"
                  status={serviceRequest?.appointments[0]?.appointmentType}
                />
              </div>
            </div>

            <div className="w-full sm:w-auto">
              {sr_ticket_media()?.length > 0 &&
                serviceRequest?.appointments[0]?.appointmentType !==
                  "PICKUP" && (
                  <button
                    onClick={() => {
                      set_view_files({
                        show: true,
                        files: sr_ticket_media(),
                        index: 0,
                      });
                    }}
                    style={{ color: "#605BFF" }}
                    className="sm:hidden mt-1 sm:mt-0 rounded bg-indigo-100 hover:bg-indigo-200 duration-200 px-3 py-2 flex flex-row items-center gap-1"
                  >
                    <ImageIcon2 className="h-5" />
                    Ticket Media
                  </button>
                )}
            </div>
          </div>
          <div className="px-5 flex flex-col gap-3">
            <span className="text-gray-600 whitespace-pre-line">
              {serviceRequest.description}
            </span>
            <span className="text-gray-600 whitespace-pre-line">
              {serviceRequest?.appointments[0]?.appointmentDescription}
            </span>
          </div>
          <div className="mt-2 bg-white sm:rounded px-5 pt-5 pb-6">
            <div className="w-full flex flex-col-reverse sm:flex-row sm:justify-between items-start">
              <div className="flex flex-col items-start gap-y-6 flex-wrap">
                {[
                  {
                    name:
                      (serviceRequest?.contactFirstName
                        ? serviceRequest?.contactFirstName + " "
                        : "") + (serviceRequest?.contactLastName ?? ""),
                    phone: serviceRequest?.contactPhone,
                  },
                  {
                    name:
                      (serviceRequest?.alternateContactFirstName
                        ? serviceRequest?.alternateContactFirstName + " "
                        : "") +
                      (serviceRequest?.alternateContactLastName ?? ""),
                    phone: serviceRequest?.alternateContactPhone,
                  },
                  {
                    name:
                      (serviceRequest?.serviceRequestContactFirstName
                        ? serviceRequest?.serviceRequestContactFirstName + " "
                        : "") +
                      (serviceRequest?.serviceRequestContactLastName ?? ""),
                    phone: serviceRequest?.serviceRequestContactPhone,
                  },
                ]
                  .filter((val) => val.name || val.phone)
                  .map((val, index) => {
                    return (
                      <Contact
                        index={index + 1}
                        name={val.name}
                        phone={val.phone}
                      />
                    );
                  })}
              </div>
              {sr_ticket_media()?.length > 0 &&
                serviceRequest?.appointments[0]?.appointmentType !==
                  "PICKUP" && (
                  <button
                    onClick={() => {
                      set_view_files({
                        show: true,
                        files: sr_ticket_media(),
                        index: 0,
                      });
                    }}
                    style={{ color: "#605BFF" }}
                    className="hidden sm:flex mt-6 mr-4 rounded bg-indigo-100 hover:bg-indigo-200 duration-200 px-3 py-2 flex-row items-center gap-1"
                  >
                    <ImageIcon2 className="h-5" />
                    Ticket Media
                  </button>
                )}
            </div>

            {serviceRequest?.ticketNotes && (
              <div className="mt-6 ml-2 flex flex-row items-start gap-4">
                <NoteIcon
                  className="mt-1"
                  style={{ height: "19px", color: "#605BFF" }}
                />
                <div className="text-gray-700 font-semibold">
                  {serviceRequest?.ticketNotes}
                </div>
              </div>
            )}

            {serviceRequest?.appointments[0]?.appointmentType !== "PICKUP" && (
              <a className="mt-6 flex flex-row items-start gap-3">
                <LocationIcon
                  className="mt-1"
                  style={{ height: "18px", color: "#605BFF" }}
                />
                <div className="w-full flex flex-col sm:flex-row items-start gap-3">
                  <div className="flex-grow text-gray-700 font-semibold">
                    {addressFormat(serviceRequest)}
                  </div>
                  {serviceRequest?.geocodeLatitude &&
                    serviceRequest?.geocodeLongitude && (
                      <a
                        href={
                          "https://www.google.com/maps/search/?api=1&query=" +
                          serviceRequest?.geocodeLatitude +
                          "," +
                          serviceRequest?.geocodeLongitude
                        }
                        target="_blank"
                        className="px-5 py-2 rounded flex flex-row items-center gap-3 text-sm text-gray-500 transform hover:scale-105 duration-200 border border-gray-400"
                      >
                        <img src="/google_map_link.png" className="h-5 w-5" />
                        Open Address in Maps
                      </a>
                    )}
                </div>
              </a>
            )}

            {serviceRequest?.accessCode && (
              <div className="mt-6 ml-2 flex flex-row items-start gap-4">
                <NumberPadIcon
                  className="mt-1"
                  style={{ height: "19px", color: "#605BFF" }}
                />
                <div className="text-gray-700 font-semibold">
                  {serviceRequest?.accessCode}
                </div>
              </div>
            )}
          </div>

          {(serviceRequest?.appointments[0]?.appointmentType === "PICKUP" ||
            serviceRequest?.appointments[0]?.appointmentType ===
              "PICKUP_AND_SERVICE") && (
            <Pickup
              supply_points={() => {
                let id = [];
                return serviceRequest?.materials
                  ?.map((ele) => {
                    if (!id.includes(ele?.supplier?.supplierId)) {
                      id = [...id, ele?.supplier?.supplierId];
                      return {
                        address: ele?.supplierBranch?.address,
                        supplierId: ele?.supplier?.supplierId,
                      };
                    }
                  })
                  ?.filter((ele) => ele);
              }}
              serviceRequest={serviceRequest}
            />
          )}

          {serviceRequest?.appointments[0]?.appointmentType !== "PICKUP" && (
            <Materials
              materials={serviceRequest?.materials}
              set_material_popup={set_material_popup}
              className="bg-white sm:rounded px-5 pt-5 pb-6"
            />
          )}

          {serviceRequest?.appointments?.[0]?.beforeUrls?.length > 0 ||
            (serviceRequest?.mediaUrls?.length > 0 && (
              <div
                className={
                  "mt-3 px-5 pt-5 pb-6 bg-white sm:rounded ml-1 flex flex-row flex-wrap gap-1 items-start w-full m-auto"
                }
              >
                <PaperClipIcon
                  style={{ color: "#605BFF" }}
                  className="h-6 relative top-1"
                />
                {[
                  ...(serviceRequest?.mediaUrls ?? []),
                  ...(serviceRequest?.appointments?.[0]?.beforeUrls ?? []),
                ]?.map((url, url_index) => (
                  <img
                    src={url.split(".").slice(0, -1).join(".") + ".jpeg"}
                    key={url_index}
                    onClick={() => {
                      set_view_files({
                        show: true,
                        files: viewFiles(),
                        index:
                          viewFiles()
                            .map((val, index) => {
                              if (val === url) return index;
                            })
                            .filter((val) => val)[0] ?? 0,
                      });
                    }}
                    className={
                      "object-cover rounded border-2 border-gray-100 hover:border-blue-600 cursor-pointer max-h-28"
                    }
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
      {serviceRequest?.appointments?.map((appointment, appointments_index) => (
        <div
          key={appointments_index}
          className="mt-3 sm:mb-4 w-full sm:max-w-5xl p-7 pb-10 sm:px-12 mx-auto flex flex-col items-start bg-white sm:rounded border-b border-gray-200 rounded-md"
        >
          <div className="w-full flex flex-col gap-7 items-start">
            <TaskTicker
              className="hidden sm:flex"
              tasks={base_tasks}
              last_finished={findAppointmentStatus({
                type: appointment.appointmentType,
                status: appointment.status,
              })}
              change_appointment_status={set_update_status_popup}
              editPrevilage={appointmentId == appointment.appointmentId}
            />
            {appointment.appointmentType !== "PICKUP" && (
              <div className="mt-3 flex flex-row gap-3 sm:gap-8 items-center px-2 text-gray-700 text-sm uppercase">
                <ClockIcon2
                  style={{ color: "#605BFF" }}
                  className="w-4 flex-shrink-0"
                />
                <div
                  style={{ fontSize: "15px" }}
                  className="flex flex-row items-center flex-wrap gap-x-2 px-2 py-1 rounded bg-gray-100"
                >
                  {appointment.timelineStart && (
                    <span>{date_format(appointment.timelineStart)}</span>
                  )}
                  {appointment.timelineStart && (
                    <span>
                      {convertTimezone(
                        appointment.timelineStart,
                        serviceRequest.timezone
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  )}
                </div>

                <span>-</span>
                <div
                  style={{ fontSize: "15px" }}
                  className="flex flex-row items-center flex-wrap gap-x-2 px-2 py-1 rounded bg-gray-100"
                >
                  {appointment.timelineEnd && (
                    <span>{date_format(appointment.timelineEnd)}</span>
                  )}
                  {appointment.timelineEnd && (
                    <span>
                      {convertTimezone(
                        appointment.timelineEnd,
                        serviceRequest.timezone
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  )}
                </div>
              </div>
            )}
            {appointment.appointmentType !== "PICKUP" && (
              <div className="flex flex-row gap-3 sm:gap-8 items-start px-2 text-sm text-gray-700">
                <WorkerIcon
                  style={{ color: "#605BFF" }}
                  className="w-4 mt-px"
                />
                <span style={{ fontSize: "16px" }}>
                  {appointment.associates.map(
                    (associate, associate_index) =>
                      (associate.firstName ? associate.firstName + " " : "") +
                      (associate.lastName ? associate.lastName : "") +
                      (associate_index < appointment.associates.length - 1
                        ? ", "
                        : "")
                  )}
                </span>
              </div>
            )}
            <TaskTicker
              className="sm:hidden"
              tasks={base_tasks}
              last_finished={appointment.status}
              change_appointment_status={set_update_status_popup}
              editPrevilage={appointmentId == appointment.appointmentId}
            />
            {appointment.appointmentType !== "PICKUP" && (
              <div className="flex flex-col sm:grid sm:grid-cols-2 gap-3 justify-between w-full">
                {[
                  {
                    label: "Before",
                    pics: [
                      ...(serviceRequest?.mediaUrls ?? []),
                      ...(appointment.beforeUrls ?? []),
                    ],
                  },
                  {
                    label: "After",
                    pics: appointment.afterUrls,
                  },
                ].map((pic, pic_index) => (
                  <div
                    key={pic_index}
                    className="bg-gray-100 rounded p-5 flex flex-col gap-4"
                  >
                    <span>{pic.label}</span>
                    {pic.pics && pic.pics.length > 0 ? (
                      <div
                        className={
                          "flex flex-row flex-wrap gap-1 items-start justify-start w-full m-auto"
                        }
                      >
                        {[...pic.pics].map((url, url_index) => (
                          <div
                            onClick={() => {
                              set_view_files({
                                show: true,
                                files: viewFiles(),
                                index:
                                  viewFiles()
                                    .map((val, index) => {
                                      if (val === url) return index;
                                    })
                                    .filter((val) => val)[0] ?? 0,
                              });
                            }}
                            className="relative max-h-28 border-2 border-gray-100 hover:border-blue-600 "
                          >
                            <img
                              src={
                                url.split(".").slice(0, -1).join(".") + ".jpeg"
                              }
                              key={url_index}
                              className={
                                "object-cover rounded cursor-pointer max-h-28"
                              }
                            />
                            {findFileTypeFromURL(url) === "video" && (
                              <div className="absolute inset-0 bg-opacity-0 flex items-center justify-center text-white hover:text-gray-500 cursor-pointer">
                                <PlayIcon className="h-8" />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="w-48 h-40 m-auto rounded text-sm flex flex-col gap-2 items-center justify-center text-red-600">
                        <WarningIcon className="w-5 h-5" />
                        <span className="text-red-400">Yet to be updated</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}

      <ImgPopup
        view={view_files.show ?? false}
        setView={() => {
          set_view_files({ show: false });
        }}
        files={view_files?.files}
        file_index={view_files.index}
        onChange={(val) => set_view_files({ ...view_files, index: val })}
      />

      <div
        onClick={() => {
          set_update_status_popup();
          set_material_popup();
        }}
        className={
          "fixed inset-0 bg-gray-800 bg-opacity-40 text-gray-700 z-30 " +
          (update_status_popup || material_popup ? "" : "hidden ")
        }
      >
        <Transition
          show={update_status_popup || material_popup ? true : false}
          className="absolute inset-0 flex items-center justify-center shadow-md p-4"
          enter="transition-all transform duration-200"
          enterFrom="scale-75 opacity-60"
          enterTo="scale-100 opacity-100"
          leave="transition-all transform duration-200"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-75 opacity-60"
        >
          {material_popup ? (
            <MaterialPopup
              material={material_popup}
              close_popup={() => set_material_popup()}
            />
          ) : update_status_popup === "ACCEPTED" ||
            update_status_popup === "REJECTED" ? (
            <UpdateStatusToAccepted
              appointmentId={appointmentId}
              update_status={update_status_popup}
              set_loading={set_loading}
              on_success={() => {
                set_update_status_popup();
                if (parameter)
                  window.location.href = "/appointment/" + appointmentId;
                else {
                  getAppointmentDetails();
                  get_appointments();
                }
              }}
              on_close={() => set_update_status_popup()}
            />
          ) : update_status_popup === "ENROUTE" ? (
            <UpdateStatusToEnroute
              appointmentId={appointmentId}
              set_loading={set_loading}
              on_success={() => {
                set_update_status_popup();
                getAppointmentDetails();
                get_appointments();
              }}
              on_close={() => set_update_status_popup()}
            />
          ) : update_status_popup === "STARTED" ? (
            <UpdateStatusToStarted
              appointmentId={appointmentId}
              set_loading={set_loading}
              on_success={() => {
                set_update_status_popup();
                getAppointmentDetails();
                get_appointments();
              }}
              on_close={() => set_update_status_popup()}
            />
          ) : update_status_popup === "FINISHED" ? (
            <UpdateStatusToFinished
              appointmentId={appointmentId}
              set_loading={set_loading}
              on_success={() => {
                set_update_status_popup();
                getAppointmentDetails();
                get_appointments();
              }}
              on_close={() => set_update_status_popup()}
            />
          ) : update_status_popup === "PICKED_UP" ? (
            <UpdateStatusToPickedUp
              appointmentId={appointmentId}
              set_loading={set_loading}
              on_success={() => {
                set_update_status_popup();
                getAppointmentDetails();
                get_appointments();
              }}
              on_close={() => set_update_status_popup()}
              status={
                serviceRequest?.appointments[0]?.appointmentType ===
                "PICKUP_AND_SERVICE"
                  ? "PICKED_UP"
                  : "STARTED"
              }
            />
          ) : update_status_popup === "DELIVERED" ? (
            <UpdateStatusToDelivered
              appointmentId={appointmentId}
              set_loading={set_loading}
              on_success={() => {
                set_update_status_popup();
                getAppointmentDetails();
                get_appointments();
              }}
              on_close={() => set_update_status_popup()}
            />
          ) : null}
        </Transition>
      </div>
    </div>
  );
}
