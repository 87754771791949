import React, { useState, useEffect } from "react";
import ActiveUserStore from "./store/ActiveUserStore";
import AppRouter from "./router/AppRouter";

import PublicRouter from "./router/PublicRouter";

export default function App() {
  const [activeUser, setActiveUser] = useState({ loading: true });

  useEffect(() => {
    setActiveUser(ActiveUserStore.getActiveUser());
    ActiveUserStore.on("change", () => {
      setActiveUser(ActiveUserStore.getActiveUser());
    });
  }, []);

  if (activeUser.loading)
    return (
      <div className="w-full h-full">
        <div className="fixed flex items-center justify-center z-40 inset-0 bg-opacity-80 bg-white">
          <div
            style={{ borderTopColor: "transparent" }}
            className="border-solid animate-spin rounded-full border-blue-400 border-4 h-12 w-12"
          ></div>
        </div>
      </div>
    );
  return (
    <div className="h-full w-full">
      {activeUser.roles && activeUser.roles.includes("ROLE_ASSOCIATE") ? (
        <AppRouter />
      ) : (
        <PublicRouter />
      )}
    </div>
  );
}
